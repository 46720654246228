var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "รายการสเตเดี้ยม" } },
    [
      _c(
        "VModal",
        {
          attrs: { title: "ข้อมูลสเตเดียม" },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("StadiumUpdateForm", {
            attrs: { "stadium-id": _vm.selectedEntityId },
            on: { success: _vm.closeUpdateForm }
          })
        ],
        1
      ),
      _c(
        "VModal",
        {
          attrs: { title: "สร้างสเตเดียม" },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [
          _c("StadiumCreateForm", {
            on: { success: _vm.onCreateEntityPagination }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "stadium-fields__top-bar" },
        [
          _c(
            "a-button",
            {
              staticClass: "stadium-holiday__add-button",
              attrs: { type: "primary" },
              on: { click: _vm.openCreateForm }
            },
            [_vm._v("\n            สร้าง\n        ")]
          )
        ],
        1
      ),
      _vm.newEntities.length > 0
        ? _c(
            "div",
            { staticClass: "stadium-boosts__new-list" },
            [
              _c("TagNew"),
              _c("VGrid", {
                attrs: { "data-source": _vm.newEntities },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("StadiumCard", {
                            attrs: { stadium: item },
                            nativeOn: {
                              click: function($event) {
                                return _vm.openUpdateForm(item.id)
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2117360214
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c("VGrid", {
        attrs: { loading: _vm.loading, "data-source": _vm.dataSource },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("StadiumCard", {
                  attrs: { stadium: item },
                  nativeOn: {
                    click: function($event) {
                      return _vm.openUpdateForm(item.id)
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("a-pagination", {
        attrs: { "page-size": _vm.size, total: _vm.total },
        model: {
          value: _vm.page,
          callback: function($$v) {
            _vm.page = $$v
          },
          expression: "page"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
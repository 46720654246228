<template>
    <div>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ชื่อ">
            <a-input
                v-decorator="[
                    'name',
                    { rules:
                        [
                            { required: true, message: 'กรุณากรอก ชื่อ ด้วยค่ะ' },
                        ]
                    }
                ]"
                placeholder="ใช้สำหรับแสดงผล" />
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="รายละเอียด">
            <a-textarea
                v-decorator="[ 'desc' ]"
                placeholder="รายละเอียดของสินค้า"
                :auto-size="{ minRows: 2, maxRows: 6 }" />
        </a-form-item>
        <BundleItemsFormItems
            :data="data"
            :form="form" />
        <VFormItemItemCondition
            :is-buy-condition="true"
            :data="data"
            :form="form"
            parent-name-prop="buyCondition" />
        <VFormItemAmountWithUnlimitOption
            :form="form"
            :data="data"
            label="จำนวน"
            name-prop="totalImportToStock" />
        <VFormItemAmountWithUnlimitOption
            :form="form"
            :data="data"
            label="จำนวนสิทธิ/1คน"
            name-prop="buyAmountLimitPerPerson"
            :initial-value="1" />
        <div>
            <a-form-item
                label="เปิดให้ซื้อได้"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }">
                <a-switch
                    :checked="isPublic"
                    @change="onToggleIsPublic" />
                <VFormItemRangeDateTime
                    v-show="isPublic"
                    :data="data"
                    :form="form"
                    :name-prop-start-time="namePropStartTime"
                    :name-prop-end-time="namePropEndTime"
                    :required="isPublic" />
            </a-form-item>
            <ProductVariantFormItems
                :form="form"
                :data="data"
                :is-update-form="isUpdateForm" />
        </div>

    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import VFormItemRangeDateTime from '@/components/VFormItemRangeDateTime.vue'
import VFormItemItemCondition from '@/components/VFormItemItemCondition.vue'
import VFormItemAmountWithUnlimitOption from '@/components/VFormItemAmountWithUnlimitOption.vue'
import ProductVariantFormItems from '@/components/ProductVariantFormItems.vue'
import BundleItemsFormItems from '@/components/BundleItemsFormItems.vue'

export default {
    components: {
        VFormItemAmountWithUnlimitOption,
        VFormItemRangeDateTime,
        VFormItemItemCondition,
        ProductVariantFormItems,
        BundleItemsFormItems,
    },
    mixins: [FormItems],
    props: {
        isUpdateForm: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            isPublic: true,
            namePropStartTime: 'publishedOn',
            namePropEndTime: 'unpublishedOn',
        }
    },
    methods: {
        onToggleIsPublic (value) {
            this.isPublic = value
            this.form.setFieldsValue({
                forcedUnpublished: !this.isPublic,
            })
        },
        mapDataToFormItems (data) {
            const {
                name,
                desc,
                publishedOn,
                unpublishedOn,
                forcedUnpublished,
                buyAmountLimitPerPerson,
            } = data

            this.isPublic = !forcedUnpublished
            && !(publishedOn == null || publishedOn === undefined)
            this.form.getFieldDecorator('forcedUnpublished', { initialValue: !!forcedUnpublished, preserve: true })

            return {
                name,
                desc,
                publishedOn,
                unpublishedOn,
                buyAmountLimitPerPerson,
            }
        },
    },
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModal",
        {
          attrs: { title: "แก้ไขข้อมูลกิจกรรมชวนเพื่อน" },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("ReferralProgramUpdateForm", {
            attrs: { id: _vm.selectedEntityId },
            on: { success: _vm.closeUpdateForm }
          })
        ],
        1
      ),
      _c("VTable", {
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          loading: _vm.loading,
          "custom-row": _vm.createClickToEditRow
        },
        scopedSlots: _vm._u([
          {
            key: "operation",
            fn: function(ref) {
              var record = ref.record
              return [
                !record.enabled
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title:
                            "ยืนยันเปิดใช้งาน? อันที่ใช้งานอยู่จะถูกแทนที่ด้วยอันนี้"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.onConfirmActiveReferralProgram(record)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "referral-program-table__operation",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    เปิดใช้งาน\n                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c("a-tag", { attrs: { color: "#f50" } }, [
                      _vm._v("\n                ใช้งานอยู่\n            ")
                    ]),
                _vm.dataSource.length
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "ยืนยันการลบ?" },
                        on: {
                          confirm: function($event) {
                            return _vm.onConfirmDelete(record)
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "referral-program-table__operation",
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [_vm._v("ลบ")]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
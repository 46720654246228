var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-item",
    {
      attrs: {
        "label-col": { span: 4 },
        "wrapper-col": { span: 12 },
        label: _vm.label
      }
    },
    [
      _c(
        "a-select",
        {
          directives: [
            {
              name: "decorator",
              rawName: "v-decorator",
              value: [
                _vm.propName,
                {
                  rules: [
                    { required: true, message: "เลือกสนามที่ต้องการโปรโมท" }
                  ]
                }
              ],
              expression:
                "[ propName ,\n                       { rules:\n                           [\n                               { required: true,\n                                 message: 'เลือกสนามที่ต้องการโปรโมท', }\n                           ] } ]"
            }
          ],
          staticStyle: { width: "240px" },
          attrs: {
            "dropdown-style": { zIndex: 1000 },
            "show-search": "",
            placeholder: "เลือกรางวัล",
            "option-filter-prop": "children",
            "filter-option": _vm.filterOption,
            loading: _vm.loading
          },
          on: { focus: _vm.onFetchEntities }
        },
        _vm._l(_vm.entities, function(referralPromotion) {
          return _c(
            "a-select-option",
            {
              key: referralPromotion.id,
              attrs: { value: referralPromotion.id }
            },
            [
              _vm._v(
                "\n            " + _vm._s(referralPromotion.name) + "\n        "
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
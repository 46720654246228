<template>
    <VForm
        v-slot="{ form }"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <StadiumCreateAndUpdateFormItems
            :form="form" />
    </VForm>
</template>
<script>
import StadiumCreateAndUpdateFormItems from '@/components/StadiumCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'
import { rootActions } from '@/store/types'

export default {
    components: {
        StadiumCreateAndUpdateFormItems,
        VForm,
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.ADD_STADIUM, { ...values },
            )
            return res
        },
    },
}
</script>

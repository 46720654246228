var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "1" } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "Info" } },
            [
              _c("VForm", {
                attrs: { data: _vm.stadium, "on-submit-async": _vm.submit },
                on: {
                  success: function(value) {
                    return this$1.$emit("success", value)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var form = ref.form
                      var data = ref.data
                      return [
                        _c("StadiumCreateAndUpdateFormItems", {
                          attrs: { data: data, form: form }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "Employee" } },
            [
              _c("Employees", {
                attrs: { "stadium-id": _vm.stadiumId, "admin-mode": true }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "Settings" } },
            [
              _c("VForm", {
                attrs: { data: _vm.stadium, "on-submit-async": _vm.submit },
                on: {
                  success: function(value) {
                    return this$1.$emit("success", value)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var form = ref.form
                      var data = ref.data
                      return [
                        _c("StadiumAdminSettingCreateAndUpdateFormItems", {
                          attrs: { data: data, form: form }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
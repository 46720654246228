var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-item",
    {
      staticClass: "switch switch--disable",
      attrs: {
        label: "ระงับการใช้งาน",
        "label-col": { span: 4 },
        "wrapper-col": { span: 12 }
      }
    },
    [
      _c("a-switch", {
        directives: [
          {
            name: "decorator",
            rawName: "v-decorator",
            value: [
              "disabled",
              { valuePropName: "checked", initialValue: false }
            ],
            expression:
              "\n            [ `disabled` ,\n              { valuePropName: 'checked',\n                initialValue: false },\n            ]"
          }
        ],
        attrs: { "checked-children": "ระงับ" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
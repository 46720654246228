<template>
    <a-form-item
        v-if="label !== ''"
        :label="label"
        has-feedback="has-feedback"
        :validate-status="null"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 12 }">
        <a-date-picker
            v-decorator="[ nameProp ,
                           {
                               normalize: normallizeDateTime,
                               rules:
                                   [
                                       { required: required,
                                         message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', }
                                   ] } ]"
            :show-time="timeOptions"
            :format="datetimeFormat"
            :placeholder="label"
            @change="onChange" />
    </a-form-item>
    <a-form-item
        v-else
        :style="{ display: 'inline-block',marginBottom: '0px',verticalAlign: 'middle' }">
        <a-date-picker
            v-decorator="[ nameProp ,
                           {
                               normalize: normallizeDateTime,
                               rules:
                                   [
                                       { required: required,
                                         message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', }
                                   ] } ]"
            :show-time="timeOptions"
            :format="datetimeFormat"
            :placeholder="label"
            @change="onChange" />
    </a-form-item>
</template>

<script>
import moment from 'moment'
import FormItems from '@/mixins/FormItems'
import MixinDateTimePicker from '@/mixins/MixinDateTimePicker'

export default {
    mixins: [FormItems, MixinDateTimePicker],
    props: {
        label: {
            type: String,
            default: '',
        },
        nameProp: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            defaule: false,
        },
    },
    data () {
        return {
            moment,
            minuteStep: 1,
            datetimeFormat: 'DD-MM-YYYY | HH:mm ',
            timeOptions: {
                minuteStep: 1,
                format: 'HH:mm',
                defaultValue: moment('00:00:00', 'HH:mm:ss'),
            },
        }
    },
    methods: {
        onChange (value) {
            this.$emit('change', value)
        },
        mapDataToFormItems (data) {
            return {
                [this.nameProp]: data[this.nameProp],
            }
        },
    },
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.label !== ""
    ? _c(
        "a-form-item",
        {
          attrs: {
            label: _vm.label,
            "has-feedback": "has-feedback",
            "validate-status": null,
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c("a-date-picker", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  _vm.nameProp,
                  {
                    normalize: _vm.normallizeDateTime,
                    rules: [
                      {
                        required: _vm.required,
                        message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ"
                      }
                    ]
                  }
                ],
                expression:
                  "[ nameProp ,\n                       {\n                           normalize: normallizeDateTime,\n                           rules:\n                               [\n                                   { required: required,\n                                     message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', }\n                               ] } ]"
              }
            ],
            attrs: {
              "show-time": _vm.timeOptions,
              format: _vm.datetimeFormat,
              placeholder: _vm.label
            },
            on: { change: _vm.onChange }
          })
        ],
        1
      )
    : _c(
        "a-form-item",
        {
          style: {
            display: "inline-block",
            marginBottom: "0px",
            verticalAlign: "middle"
          }
        },
        [
          _c("a-date-picker", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  _vm.nameProp,
                  {
                    normalize: _vm.normallizeDateTime,
                    rules: [
                      {
                        required: _vm.required,
                        message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ"
                      }
                    ]
                  }
                ],
                expression:
                  "[ nameProp ,\n                       {\n                           normalize: normallizeDateTime,\n                           rules:\n                               [\n                                   { required: required,\n                                     message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', }\n                               ] } ]"
              }
            ],
            attrs: {
              "show-time": _vm.timeOptions,
              format: _vm.datetimeFormat,
              placeholder: _vm.label
            },
            on: { change: _vm.onChange }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <a-form-item
            label="เข้าร่วมในระบบแล้ว"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 10 }">
            <a-switch
                v-decorator="
                    [ `isOfficial` ,
                      { valuePropName: 'checked',
                        initialValue: false },
                    ]"
                checked-children="Lenkila Official" />
        </a-form-item>
        <a-form-item
            label="
                คอมมิชชั่น
                (%)"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 10 }">
            <a-input-number
                v-decorator="
                    [ 'generalCommissionRate' ]"
                :min="0" />
        </a-form-item>
        <a-form-item
            label="คอมมิชชั่น Ads (%)"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 10 }">
            <a-input-number
                v-decorator="
                    [ 'adsCommissionRate' ]"
                :min="0" />
        </a-form-item>
        <StadiumTaxCreateAndUpdateFormItems :form="form" :data="data" />
    </div>
</template>

<script>
import FormItems from '@/mixins/FormItems'
import StadiumTaxCreateAndUpdateFormItems from '@/components/StadiumTaxCreateAndUpdateFormItems.vue'

export default {
    components: {
        StadiumTaxCreateAndUpdateFormItems,
    },
    mixins: [FormItems],
    methods: {
        mapDataToFormItems (data) {
            const {
                generalCommissionRate,
                adsCommissionRate,
                isOfficial,
            } = data
            return {
                generalCommissionRate,
                adsCommissionRate,
                isOfficial,
            }
        },
    },
}
</script>

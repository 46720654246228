<template>
    <VPage title="รายการ Banner">
        <AdminBannerMediasVue />
    </VPage>
</template>
<script>
import VPage from '@/components/VPage.vue'
import AdminBannerMediasVue from '@/views/AdminBannerMedias.vue'

export default {
    components: {
        VPage,
        AdminBannerMediasVue,
    },
}
</script>

<template>
    <div class="stadium-boosts">
        <VModal
            v-model="visibleCreateForm"
            title="สร้างรายการ Banner">
            <BannerMediaCreateFormVue @success="onCreateEntityPagination" />
        </VModal>
        <VModal
            v-model="visibleUpdateForm"
            :after-close="clearSelectedEntityId"
            title="แก้ไขรายการ Banner">
            <BannerMediaUpdateFormVue
                :id="selectedEntityId"
                @success="closeUpdateForm" />
        </VModal>
        <div class="admin-banner-medias__top-bar">
            <a-input-search
                placeholder="ค้นหา"
                style="width: 400px;margin-right:18px;"
                @change="handleQueryChange" />
            <a-button
                type="primary"
                @click="openCreateForm">
                สร้าง
            </a-button>
        </div>
        <SectionNew v-if="newEntities.length > 0">
            <VGrid
                v-slot="{ item }"
                :grid="grid"
                :data-source="newEntities">
                <BannerMediaCard
                    :banner-media="item"
                    @click.native="onClickItem(item)" />
            </VGrid>
        </SectionNew>
        <VGrid
            v-slot="{ item }"
            :grid="grid"
            :data-source="entities">
            <BannerMediaCard
                :banner-media="item"
                @click.native="onClickItem(item)" />
        </VGrid>
    </div>
</template>
<script>
import SectionNew from '@/components/SectionNew.vue'
import VGrid from '@/components/VGrid.vue'
import VModal from '@/components/VModal.vue'
import BannerMediaCard from '@/components/BannerMediaCard.vue'
import { rootActions } from '@/store/types'
import EntitiesPagination from '@/mixins/EntitiesPagination'
import BannerMediaCreateFormVue from '@/components/BannerMediaCreateForm.vue'
import BannerMediaUpdateFormVue from '@/components/BannerMediaUpdateForm.vue'

export default {
    components: {
        BannerMediaCard,
        BannerMediaCreateFormVue,
        BannerMediaUpdateFormVue,
        SectionNew,
        VModal,
        VGrid,
    },
    mixins: [EntitiesPagination],
    props: {
        disableOpenUpdateFormOnClick: {
            type: Boolean,
            default: false,
        },
        isPast: {
            type: Boolean,
            default: null,
        },
    },
    data () {
        return {
            grid: {
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 2,
                xl: 2,
                xxl: 2,
            },
        }
    },
    methods: {
        onClickItem (item) {
            this.$emit('clickItem', item)
            if (!this.disableOpenUpdateFormOnClick) this.openUpdateForm(item.id)
        },
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_BANNER_MEDIAS, { isPast: this.isPast, page, size },
            )
            return res
        },
        getAllEntities () {
            return this.$store.state.banner.bannerMedias
        },
    },
}
</script>
<style lang="stylus">
.admin-banner-medias__top-bar
    margin-bottom 24px
    button
        margin-left 8px
// .stadium-boosts__new-list
//     background-color #fffbe6
//     padding 8px
//     margin-bottom 16px
</style>

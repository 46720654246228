var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VFormItemRangeDateTimeVue", {
        attrs: { data: _vm.data, form: _vm.form }
      }),
      _c("VFormItemPhotoVue", {
        attrs: {
          data: _vm.data,
          form: _vm.form,
          label: "รูป Banner",
          "prop-name": "imageUrl",
          width: _vm.ImageSize.w,
          height: _vm.ImageSize.h
        }
      }),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ลิงค์"
          }
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "linkUrl",
                  { rules: [{ validator: _vm.urlValidator }] }
                ],
                expression:
                  "\n                [ 'linkUrl',{ rules:\n                    [\n                        { validator: urlValidator },\n                    ] } ]"
              }
            ]
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            label: "เปิด Ad",
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c("a-switch", {
            attrs: { checked: _vm.isAd },
            on: { change: _vm.onToggleIsAd }
          })
        ],
        1
      ),
      _vm.isAd
        ? _c(
            "div",
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-col": { span: 4 },
                    "wrapper-col": { span: 12 },
                    label: "ชื่อ Ad"
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "gaAdUnitName",
                          {
                            rules: [
                              {
                                required: true,
                                message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ"
                              }
                            ]
                          }
                        ],
                        expression:
                          "\n                    [ 'gaAdUnitName',\n                      { rules:\n                          [\n                              { required: true,\n                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
                      }
                    ],
                    attrs: { placeholder: "ex Adidas New Year Sale" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    "label-col": { span: 4 },
                    "wrapper-col": { span: 12 },
                    label: "เจ้าของ Ad"
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "gaAdSource",
                          {
                            rules: [
                              {
                                required: true,
                                message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ"
                              }
                            ]
                          }
                        ],
                        expression:
                          "\n                    [ 'gaAdSource',\n                      { rules:\n                          [\n                              { required: true,\n                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
                      }
                    ],
                    attrs: { placeholder: "ex Adidas, Nike" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "ราคา/การมองเห็น",
                    "label-col": { span: 4 },
                    "wrapper-col": { span: 12 }
                  }
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "gaValue",
                          {
                            rules: [
                              {
                                required: true,
                                message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ"
                              }
                            ]
                          }
                        ],
                        expression:
                          "\n                    [ `gaValue` ,\n                      { rules:\n                          [\n                              { required: true,\n                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
                      }
                    ],
                    attrs: { min: 0 }
                  }),
                  _vm._v(
                    "\n            = " +
                      _vm._s(
                        _vm._f("formatCurrency")(
                          _vm.form.getFieldValue("gaValue")
                        )
                      ) +
                      "\n        "
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModalVue",
        {
          attrs: { title: "เลือกรายการ banner" },
          model: {
            value: _vm.visibleSelectBannerMedia,
            callback: function($$v) {
              _vm.visibleSelectBannerMedia = $$v
            },
            expression: "visibleSelectBannerMedia"
          }
        },
        [
          _c("AdminBannerMediasVue", {
            attrs: { "disable-open-update-form-on-click": true },
            on: { clickItem: _vm.onSelectBannerMedia }
          })
        ],
        1
      ),
      _c(
        "VModalVue",
        {
          attrs: {
            "after-close": _vm.clearSelectedEntityId,
            title: "แก้ไขรายการ Banner"
          },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("BannerMediaUpdateFormVue", {
            attrs: { id: _vm.selectedEntityId },
            on: { success: _vm.closeUpdateForm }
          })
        ],
        1
      ),
      _c("VFormItemArrayVue", {
        ref: "bannerMediaFormItem",
        attrs: {
          form: _vm.form,
          data: _vm.data,
          "is-update-form": _vm.isUpdateForm,
          label: "รายการ Banner",
          "prop-name": "bannerMediaIds",
          "prevent-default-click-add": true
        },
        on: { clickAdd: _vm.onClickAdd },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm.getBannerMediaById(item)
                  ? _c(
                      "div",
                      {
                        class: {
                          "has-error": _vm
                            .getBannerMediaById(item)
                            .end.isBefore(_vm.moment())
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm
                                .getBannerMediaById(item)
                                .end.isBefore(_vm.moment())
                                ? "หมดอายุแล้ว (จะไม่แสดงผลในแอป)"
                                : ""
                            ) +
                            "\n                "
                        ),
                        _c(
                          "div",
                          { staticClass: "banner-media__container" },
                          [
                            _c("BannerMediaCardVue", {
                              attrs: {
                                "banner-media": _vm.getBannerMediaById(item),
                                "enable-delete": false
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.openUpdateForm(item)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "รางวัลคูปองส่วนลด" } },
    [
      _c(
        "VModal",
        {
          attrs: { title: "สร้างรางวัล" },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [
          _c("ReferralPromotionCreateForm", {
            on: { success: _vm.onCreateEntityPagination }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "manager__top-bar" },
        [
          _c("a-input-search", {
            staticStyle: { width: "400px", "margin-right": "18px" },
            attrs: { placeholder: "ค้นหา" },
            on: { change: _vm.handleQueryChange }
          }),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.onOpenCreateForm } },
            [_vm._v("\n            สร้าง\n        ")]
          )
        ],
        1
      ),
      _vm.newEntities.length > 0
        ? _c(
            "SectionNew",
            [
              _c("ReferralPromotionTable", {
                attrs: {
                  "data-source": _vm.newEntities,
                  loading: _vm.loading,
                  "custom-row": _vm.createClickToEditRow,
                  pagination: _vm.pagination
                },
                on: {
                  change: _vm.handleTableChange,
                  delete: _vm.onDeleteEntityPagination
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("ReferralPromotionTable", {
        attrs: {
          "data-source": _vm.dataSource,
          loading: _vm.loading,
          "custom-row": _vm.createClickToEditRow,
          pagination: _vm.pagination
        },
        on: {
          change: _vm.handleTableChange,
          delete: _vm.onDeleteEntityPagination
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
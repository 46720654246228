var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-item",
    {
      attrs: {
        label: _vm.label,
        "label-col": { span: 4 },
        "wrapper-col": { span: 12 }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "a-radio-group",
            {
              on: { change: _vm.onChangeLimitedMode },
              model: {
                value: _vm.isLimited,
                callback: function($$v) {
                  _vm.isLimited = $$v
                },
                expression: "isLimited"
              }
            },
            [
              _c("a-radio", { attrs: { value: true } }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLimited,
                        expression: "isLimited"
                      }
                    ]
                  },
                  [
                    _c("a-input-number", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            [_vm.nameProp],
                            Object.assign(
                              {},
                              _vm.initialValue !== null
                                ? { initialValue: _vm.initialValue }
                                : {},
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ"
                                  }
                                ]
                              }
                            )
                          ],
                          expression:
                            "\n                            [ [ nameProp ] ,\n                              {\n                                  ...initialValue !== null ?\n                                      { initialValue: initialValue } : {},\n                                  rules:\n                                      [\n                                          { required: true,\n                                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', }\n                                      ] } ]"
                        }
                      ],
                      attrs: { min: 1 }
                    })
                  ],
                  1
                )
              ]),
              _c("a-radio", { attrs: { value: false } }, [
                _vm._v("\n                ไม่จำกัด\n            ")
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
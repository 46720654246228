var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ชื่อส่วนลด"
          }
        },
        [
          _c(
            "a-input",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "name",
                    {
                      rules: [
                        {
                          required: true,
                          message: "กรุณากรอก ชื่อส่วนลด ด้วยค่ะ"
                        }
                      ]
                    }
                  ],
                  expression:
                    "[\n                'name',\n                { rules:\n                    [\n                        { required: true, message: 'กรุณากรอก ชื่อส่วนลด ด้วยค่ะ' },\n                    ]\n                }\n            ]"
                }
              ],
              attrs: {
                placeholder: "ชื่อ เช่น ส่วนลดปีใหม่ 50% เฉพาะลูกค้าใหม่"
              }
            },
            [
              _c("a-icon", {
                attrs: { slot: "prefix", type: "user" },
                slot: "prefix"
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "รายละเอียด"
          }
        },
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["desc"],
                expression: "[ 'desc' ]"
              }
            ],
            attrs: {
              placeholder: "รายละเอียดของส่วนลดแบบละเอียด",
              "auto-size": { minRows: 2, maxRows: 6 }
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ประเภทส่วนลด"
          }
        },
        [
          _c(
            "a-radio-group",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "discountType",
                    {
                      initialValue: _vm.DiscountType.PRICE
                    }
                  ],
                  expression:
                    "\n                [ 'discountType',\n                  {\n                      initialValue: DiscountType.PRICE\n                  }\n                ]"
                }
              ]
            },
            [
              _c("a-radio", { attrs: { value: _vm.DiscountType.PRICE } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("formatDiscountTypeEnum")(_vm.DiscountType.PRICE)
                    ) +
                    "\n            "
                )
              ]),
              _c("a-radio", { attrs: { value: _vm.DiscountType.PERCENT } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("formatDiscountTypeEnum")(_vm.DiscountType.PERCENT)
                    ) +
                    "\n            "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("VFormItemNumberWithBar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.form.getFieldValue("discountType") ===
              _vm.DiscountType.PERCENT,
            expression:
              "form.getFieldValue('discountType') === DiscountType.PERCENT"
          }
        ],
        attrs: {
          "name-prop": "discountValue",
          form: _vm.form,
          data: _vm.data,
          min: 1,
          max: 100,
          label: "ส่วนลด (%)",
          required: true
        }
      }),
      _c(
        "a-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.form.getFieldValue("discountType") ===
                _vm.DiscountType.PRICE,
              expression:
                "form.getFieldValue('discountType') === DiscountType.PRICE"
            }
          ],
          attrs: {
            label: "ส่วนลด (บาท)",
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "discountValue",
                  {
                    rules: [
                      { required: true, message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ" }
                    ]
                  }
                ],
                expression:
                  "\n                [ `discountValue`,\n                  { rules:\n                      [\n                          { required:\n                                true,\n                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },\n                      ] } ]"
              }
            ],
            attrs: { min: 0 }
          }),
          _vm._v(
            "\n        = " +
              _vm._s(
                _vm._f("formatCurrency")(
                  _vm.form.getFieldValue("discountValue")
                )
              ) +
              "\n    "
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.form.getFieldValue("discountType") ===
                _vm.DiscountType.PERCENT,
              expression:
                "form.getFieldValue('discountType') === DiscountType.PERCENT"
            }
          ],
          attrs: {
            label: "ลดสูงสุด (บาท)",
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["maxDiscountAmount"],
                expression: "\n                [ `maxDiscountAmount` ]"
              }
            ],
            attrs: { min: 1 }
          }),
          _vm._v(
            "\n        = " +
              _vm._s(
                _vm._f("formatCurrency")(
                  _vm.form.getFieldValue("maxDiscountAmount")
                )
              ) +
              "\n    "
          )
        ],
        1
      ),
      _c("VFormItemItemCondition", {
        attrs: {
          data: _vm.data,
          form: _vm.form,
          "parent-name-prop": "useCondition"
        }
      }),
      _c("VFormItemAmountWithUnlimitOption", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.referralMode && !_vm.couponForBundleMode,
            expression: "!referralMode && !couponForBundleMode"
          }
        ],
        attrs: {
          form: _vm.form,
          data:
            _vm.referralMode || _vm.couponForBundleMode
              ? { totalImportToStock: -1 }
              : _vm.data,
          label: "จำนวนสิทธิ",
          "name-prop": "totalImportToStock"
        }
      }),
      !_vm.referralMode
        ? _c("VFormItemAmountWithUnlimitOption", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.referralMode && !_vm.couponForBundleMode,
                expression: "!referralMode && !couponForBundleMode"
              }
            ],
            attrs: {
              form: _vm.form,
              data: _vm.couponForBundleMode
                ? { claimAmountLimitPerPerson: -1 }
                : _vm.data,
              label: "จำนวนสิทธิ/1คน",
              "name-prop": "claimAmountLimitPerPerson",
              "initial-value": 1
            }
          })
        : _vm._e(),
      _vm.referralMode || _vm.couponForBundleMode
        ? _c(
            "a-form-item",
            {
              attrs: {
                label: "อายุการใช้งาน",
                "label-col": { span: 4 },
                "wrapper-col": { span: 12 }
              }
            },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "duration",
                      {
                        rules: [
                          {
                            required: true,
                            message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ"
                          }
                        ]
                      }
                    ],
                    expression:
                      "\n                [ `duration`,\n                  { rules:\n                      [\n                          { required:\n                                true,\n                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },\n                      ] } ]"
                  }
                ],
                attrs: { min: 0 }
              }),
              _vm._v(
                "\n        = " +
                  _vm._s(_vm.form.getFieldValue("duration")) +
                  " วัน\n    "
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c("VFormItemRangeDateTime", {
                attrs: { data: _vm.data, form: _vm.form, required: true },
                on: { startDateTimeChange: _vm.onStartDateTimeChange }
              }),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "เปิดให้เก็บได้",
                    "label-col": { span: 4 },
                    "wrapper-col": { span: 12 }
                  }
                },
                [
                  _c("a-switch", {
                    attrs: { checked: _vm.isPublic },
                    on: { change: _vm.onToggleIsPublic }
                  }),
                  _c(
                    "div",
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isPublic,
                              expression: "isPublic"
                            }
                          ],
                          on: { change: _vm.onChangePublicTimeSettingMode },
                          model: {
                            value: _vm.publicTimeSettingMode,
                            callback: function($$v) {
                              _vm.publicTimeSettingMode = $$v
                            },
                            expression: "publicTimeSettingMode"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v(
                              "\n                        พร้อมเวลาเริ่ม\n                    "
                            )
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [
                            _vm._v(
                              "\n                        อื่นๆ\n                        "
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isManualPublishedTimeSettingMode,
                                    expression:
                                      "isManualPublishedTimeSettingMode"
                                  }
                                ]
                              },
                              [
                                _c("VFormItemDateTime", {
                                  attrs: {
                                    form: _vm.form,
                                    required:
                                      _vm.isManualPublishedTimeSettingMode,
                                    "name-prop": _vm.namePropManualPublishedOn
                                  },
                                  on: { change: _vm.onChangeManualPublishedOn }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ]
                        },
                        [
                          _c("VFormItemDateTime", {
                            attrs: {
                              form: _vm.form,
                              data: _vm.data,
                              "name-prop": _vm.namePropPublishedOn
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "a-form-item",
        {
          staticClass: "switch switch--disable",
          attrs: {
            label: "ระงับการใช้งาน",
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c("a-switch", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "disabled",
                  { valuePropName: "checked", initialValue: false }
                ],
                expression:
                  "\n                [ `disabled` ,\n                  { valuePropName: 'checked',\n                    initialValue: false },\n                ]"
              }
            ],
            attrs: { "checked-children": "ระงับ" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
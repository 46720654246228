var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "รหัส ส่วนลด"
          }
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "code",
                  {
                    normalize: _vm.normallizeCode,
                    rules: [
                      { required: true, message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ" }
                    ]
                  }
                ],
                expression:
                  "\n                [ 'code',\n                  {\n                      normalize: normallizeCode,\n                      rules:\n                          [\n                              { required: true,\n                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
              }
            ]
          })
        ],
        1
      ),
      _c("DiscountCreateAndUpdateFormItems", {
        attrs: { form: _vm.form, data: _vm.data }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("สินค้าในแพ๊กเกจ")]),
      _vm._l(_vm.form.getFieldValue("itemKeys"), function(k) {
        return _c(
          "a-form-item",
          _vm._b(
            { key: k, attrs: { required: false } },
            "a-form-item",
            _vm.formItemLayout,
            false
          ),
          [
            _c(
              "a-card",
              [
                k >= 1
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "ยืนยันการลบ?" },
                        on: {
                          confirm: function() {
                            return _vm.remove(k)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "product-variant-form-items__remove-button",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    ลบ\n                    "
                            ),
                            k >= 1
                              ? _c("a-icon", {
                                  staticClass: "dynamic-delete-button",
                                  style: { fontSize: "16px" },
                                  attrs: { type: "delete" }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "a-form-item",
                  {
                    attrs: {
                      "label-col": { span: 4 },
                      "wrapper-col": { span: 12 },
                      label: "สินค้า"
                    }
                  },
                  [
                    _c(
                      "a-select",
                      {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "items[" + k + "][itemId]",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "เลือกสินค้าที่ต้องการใส่ในแพ็กเกจ"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[ `items[${k}][itemId]` ,\n                                   { rules:\n                                       [\n                                           {\n                                               required: true,\n                                               message: 'เลือกสินค้าที่ต้องการใส่ในแพ็กเกจ',\n                                           }\n                                       ]\n                                   }\n                    ]"
                          }
                        ],
                        staticStyle: { width: "240px" },
                        attrs: {
                          "show-search": "",
                          placeholder: "เลือกสินค้า",
                          "option-filter-prop": "children",
                          "filter-option": _vm.filterOption,
                          loading: _vm.isLoadCouponForBundles
                        },
                        on: { focus: _vm.loadCouponForBundles }
                      },
                      _vm._l(_vm.couponForBundles, function(couponForBundle) {
                        return _c(
                          "a-select-option",
                          {
                            key: couponForBundle.id,
                            attrs: { value: couponForBundle.id }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(couponForBundle.name) +
                                "\n                    "
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-form-item",
                  {
                    attrs: {
                      label: "จำนวนสินค้าต่อชุด",
                      help:
                        "เช่น ถ้าใส่ค่าเป็น 2 = ซื้อ 1 แพ๊กเกจ ก็คือจะได้ สินค้านี้ 2 ชิน",
                      "label-col": { span: 4 },
                      "wrapper-col": { span: 12 }
                    }
                  },
                  [
                    _c("a-input-number", {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "items[" + k + "][amount]",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ"
                                }
                              ]
                            }
                          ],
                          expression:
                            "\n                        [ `items[${k}][amount]`,\n                          {\n                              rules:\n                                  [\n                                      { required: true,\n                                        message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },\n                                  ] } ]"
                        }
                      ],
                      attrs: { min: 1 }
                    }),
                    _vm.form.getFieldValue("items[" + k + "][amount]")
                      ? [
                          _vm._v(
                            "\n                    = ซื้อ 1 ชุด\n                    "
                          ),
                          _c("a-icon", { attrs: { type: "arrow-right" } }),
                          _vm._v(
                            "\n                    ได้\n                    "
                          ),
                          _c("strong", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.form.getFieldValue(
                                    "items[" + k + "][amount]"
                                  )
                                ) +
                                "\n                    "
                            )
                          ]),
                          _vm._v(" ชิ้น\n                ")
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm.form.getFieldValue("items[" + k + "][itemId]")
                  ? _c("ReferralPromotionById", {
                      attrs: {
                        id: _vm.form.getFieldValue("items[" + k + "][itemId]")
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "a-form-item",
        _vm._b({}, "a-form-item", _vm.formItemLayoutWithOutLabel, false),
        [
          _c(
            "a-button",
            {
              staticStyle: { width: "60%" },
              attrs: { type: "dashed" },
              on: { click: _vm.add }
            },
            [
              _c("a-icon", { attrs: { type: "plus" } }),
              _vm._v(" เพิ่มสินค้าอื่น\n        ")
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <VModal
            v-model="visibleUpdateForm"
            :title="updateFormTitle">
            <slot
                name="updateForm"
                :selectedEntityId="selectedEntityId"
                :onSuccess="closeUpdateForm">
                <CouponUpdateForm
                    v-if="promotionCodeType === PromotionCodeType.COUPON"
                    :id="selectedEntityId"
                    @success="closeUpdateForm" />
                <DiscountCodeUpdateForm
                    v-else
                    :id="selectedEntityId"
                    @success="closeUpdateForm" />
            </slot>
        </VModal>
        <VTable
            :data-source="dataSource"
            :columns="columns"
            :loading="loading"
            :pagination="pagination"
            :custom-row="createClickToEditRow"
            :row-class-name="rowClassName"
            @change="handleTableChange">
            <template
                v-slot:discountType="{ text }">
                {{ text | formatDiscountTypeEnum }}
            </template>
            <template
                v-slot:operation="{ record }">
                <a-popconfirm
                    v-if="dataSource.length"
                    title="ยืนยันการลบ?"
                    @confirm="onConfirmDelete(record)">
                    <a href="javascript:;" @click.stop>ลบ</a>
                </a-popconfirm>
            </template>
        </VTable>
    </div>
</template>
<script>
import MixinTable from '@/mixins/MixinTable'
import { PromotionCodeType } from '@/utils/enum'
import CouponUpdateForm from '@/components/CouponUpdateForm.vue'
import DiscountCodeUpdateForm from '@/components/DiscountCodeUpdateForm.vue'
import VModal from '@/components/VModal.vue'
import VTable from '@/components/VTable.vue'
import moment from 'moment'

export default {
    components: {
        CouponUpdateForm,
        DiscountCodeUpdateForm,
        VModal,
        VTable,
    },
    mixins: [MixinTable],
    props: {
        promotionCodeType: {
            type: String,
            required: true,
        },
        expireByDuration: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            PromotionCodeType,
            preUniqueDiscountCodeColumns: [
                {
                    title: 'โค้ด',
                    dataIndex: 'code',
                    width: 100,
                    scopedSlots: { customRender: 'text' },
                },
            ],
            preUniqueCouponColumns: [],
            postUniqueDiscountCodeColumns: [
                {
                    title: 'บันทึกไปแล้ว',
                    dataIndex: 'noSaved',
                    width: 150,
                    scopedSlots: { customRender: 'amount' },
                },
                {
                    title: 'ใช้ไปแล้ว',
                    dataIndex: 'noUsed',
                    width: 150,
                    scopedSlots: { customRender: 'amount' },
                },
            ],
            postUniqueCouponColumns: [
                {
                    title: 'แจกไปแล้ว',
                    dataIndex: 'noClaimed',
                    width: 150,
                    scopedSlots: { customRender: 'amount' },
                },
                {
                    title: 'ใช้ไปแล้ว',
                    dataIndex: 'noUsed',
                    width: 150,
                    scopedSlots: { customRender: 'amount' },
                },
            ],
            operationColumns: [
                {
                    title: 'การจัดการ',
                    dataIndex: 'operation',
                    width: 100,
                    fixed: 'right',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
            baseColumns: [
                {
                    title: 'ชื่อ',
                    dataIndex: 'name',
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'ประเภท',
                    dataIndex: 'discountType',
                    width: 100,
                    scopedSlots: { customRender: 'discountType' },
                },
                {
                    title: 'ลดราคา',
                    dataIndex: 'discountValue',
                    width: 150,
                    scopedSlots: { customRender: 'text' },
                },
                ...this.expireByDuration ? [
                    {
                        title: 'อายุการใช้งาน',
                        dataIndex: 'duration',
                        width: 150,
                        scopedSlots: { customRender: 'text' },
                    },
                ] : [
                    {
                        title: 'วันเริ่ม',
                        dataIndex: 'start',
                        width: 150,
                        scopedSlots: { customRender: 'date' },
                    },
                    {
                        title: 'วันหมดอายุ',
                        dataIndex: 'end',
                        width: 150,
                        scopedSlots: { customRender: 'date' },
                    },
                ],
                {
                    title: 'จำนวนที่มี',
                    dataIndex: 'totalImportToStock',
                    width: 150,
                    scopedSlots: { customRender: 'amount' },
                },
            ],
        }
    },
    computed: {
        columns () {
            return [
                ...this.promotionCodeType === PromotionCodeType.COUPON
                    ? this.preUniqueCouponColumns : this.preUniqueDiscountCodeColumns,
                ...this.baseColumns,
                ...this.promotionCodeType === PromotionCodeType.COUPON
                    ? this.postUniqueCouponColumns : this.postUniqueDiscountCodeColumns,
                ...this.operationColumns,
            ]
        },
        updateFormTitle () {
            return this.promotionCodeType === PromotionCodeType.COUPON
                ? 'คูปองส่วนลด' : 'โค้ดส่วนลด'
        },
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        rowClassName (record, _index, _indent) {
            if (moment().isBetween(record.validOn, record.invalidOn)) return 'discount-active'
            return ''
        },
    },
}
</script>
<style lang="stylus">
.discount-active
    background_primary-gradient-color()
    color: white
    a
        color: white
        text-decoration: underline
.ant-table-row-hover
    color: $primary-color
    a
        color: $primary-color
.ant-table-scroll  tr.discount-active
    border-left: 5px solid $tournament-color

</style>

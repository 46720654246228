<template>
    <div>
        <VModal
            v-model="visibleUpdateForm"
            title="แก้ไขข้อมูลกิจกรรมชวนเพื่อน">
            <ReferralProgramUpdateForm
                :id="selectedEntityId"
                @success="closeUpdateForm" />
        </VModal>
        <VTable
            :data-source="dataSource"
            :columns="columns"
            :loading="loading"
            :custom-row="createClickToEditRow">
            <template
                v-slot:operation="{ record }">
                <a-popconfirm
                    v-if="!record.enabled"
                    title="ยืนยันเปิดใช้งาน? อันที่ใช้งานอยู่จะถูกแทนที่ด้วยอันนี้"
                    @confirm="onConfirmActiveReferralProgram(record)">
                    <a-button
                        type="primary"
                        class="referral-program-table__operation"
                        @click.stop>
                        เปิดใช้งาน
                    </a-button>
                </a-popconfirm>
                <a-tag v-else color="#f50">
                    ใช้งานอยู่
                </a-tag>
                <a-popconfirm
                    v-if="dataSource.length"
                    title="ยืนยันการลบ?"
                    @confirm="onConfirmDelete(record)">
                    <a
                        href="javascript:;"
                        class="referral-program-table__operation"
                        @click.stop>ลบ</a>
                </a-popconfirm>
            </template>
        </VTable>
    </div>
</template>
<script>
import MixinTable from '@/mixins/MixinTable'
import ReferralProgramUpdateForm from '@/components/ReferralProgramUpdateForm.vue'
import VModal from '@/components/VModal.vue'
import VTable from '@/components/VTable.vue'
import { rootActions } from '@/store/types'

export default {
    components: {
        ReferralProgramUpdateForm,
        VModal,
        VTable,
    },
    mixins: [MixinTable],
    data () {
        return {
            operationColumns: [
                {
                    title: 'การจัดการ',
                    dataIndex: 'operation',
                    width: '160px',
                    fixed: 'right',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
            baseColumns: [
                {
                    title: 'ชื่อ',
                    dataIndex: 'title',
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'คำบรรยายเบื้องต้น',
                    dataIndex: 'desc',
                    scopedSlots: { customRender: 'text' },
                },
            ],
        }
    },
    computed: {
        columns () {
            return [
                ...this.baseColumns,
                ...this.operationColumns,
            ]
        },
    },
    methods: {
        onConfirmActiveReferralProgram (record) {
            this.$store.dispatch(rootActions.ACTIVE_REFERRAL_PROGRAM, record.id)
        },
    },
}
</script>
<style lang="stylus">
.referral-program-table__operation
    margin-left:8px
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "ผู้จัดการ" } },
    [
      _c(
        "VModal",
        {
          attrs: { title: "สร้าง ผู้จัดการ" },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [
          _c("ManagerCreateForm", {
            on: { success: _vm.onCreateEntityPagination }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "manager__top-bar" },
        [
          _c("a-input-search", {
            staticStyle: { width: "400px", "margin-right": "18px" },
            attrs: { placeholder: "คัดกรองโดย ชื่อ, เบอร์ หรือ อีเมล" },
            on: { change: _vm.handleQueryChange }
          }),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.openCreateForm } },
            [_vm._v("\n            สร้าง\n        ")]
          )
        ],
        1
      ),
      _c(
        "SectionNew",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.newEntities.length > 0,
              expression: "newEntities.length > 0"
            }
          ]
        },
        [
          _c("TableManagers", {
            attrs: { "data-source": _vm.newEntities, loading: _vm.loading },
            on: { delete: _vm.onDeleteEntityPagination }
          })
        ],
        1
      ),
      _c("TableManagers", {
        attrs: {
          "data-source": _vm.dataSource,
          loading: _vm.loading,
          pagination: _vm.pagination
        },
        on: {
          delete: _vm.onDeleteEntityPagination,
          change: _vm.handleTableChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
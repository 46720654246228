var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VFormItemDisableSwitch", {
        attrs: { form: _vm.form, data: _vm.data }
      }),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ประเภท"
          }
        },
        [
          _c(
            "a-radio-group",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "amountType",
                    {
                      initialValue: _vm.DiscountType.PRICE
                    }
                  ],
                  expression:
                    "\n                [ 'amountType',\n                  {\n                      initialValue: DiscountType.PRICE\n                  }\n                ]"
                }
              ]
            },
            [
              _c("a-radio", { attrs: { value: _vm.DiscountType.PRICE } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("formatDiscountTypeEnum")(_vm.DiscountType.PRICE)
                    ) +
                    "\n            "
                )
              ]),
              _c("a-radio", { attrs: { value: _vm.DiscountType.PERCENT } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("formatDiscountTypeEnum")(_vm.DiscountType.PERCENT)
                    ) +
                    "\n            "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("VFormItemNumberWithBar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.form.getFieldValue("amountType") === _vm.DiscountType.PERCENT,
            expression:
              "form.getFieldValue('amountType') === DiscountType.PERCENT"
          }
        ],
        attrs: {
          "name-prop": "applicableValue",
          form: _vm.form,
          data: _vm.data,
          min: 0,
          max: 100,
          label: "เงื่อนไข ใช้เงินคืน (%)",
          required: true
        }
      }),
      _c(
        "a-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.form.getFieldValue("amountType") === _vm.DiscountType.PRICE,
              expression:
                "form.getFieldValue('amountType') === DiscountType.PRICE"
            }
          ],
          attrs: {
            label: "สูงสุดไม่เกิน (บาท)",
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "applicableValue",
                  {
                    rules: [
                      { required: true, message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ" }
                    ]
                  }
                ],
                expression:
                  "\n                [ `applicableValue`,\n                  { rules:\n                      [\n                          { required:\n                                true,\n                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },\n                      ] } ]"
              }
            ],
            attrs: { min: 0 }
          }),
          _vm._v(
            "\n        = " +
              _vm._s(
                _vm._f("formatCurrency")(
                  _vm.form.getFieldValue("applicableValue")
                )
              ) +
              "\n    "
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.form.getFieldValue("amountType") ===
                _vm.DiscountType.PERCENT,
              expression:
                "form.getFieldValue('amountType') === DiscountType.PERCENT"
            }
          ],
          attrs: {
            label: "สูงสุดไม่เกิน (บาท)",
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["maximumApplicableAmount"],
                expression: "\n                [ `maximumApplicableAmount` ]"
              }
            ],
            attrs: { min: 0 }
          }),
          _vm._v(
            "\n        = " +
              _vm._s(
                _vm._f("formatCurrency")(
                  _vm.form.getFieldValue("maximumApplicableAmount")
                )
              ) +
              "\n    "
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <VForm
        v-slot="{ form }"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <ReferralProgramCreateAndUpdateFormItems :form="form" :data="referralProgram" />
    </VForm>
</template>
<script>
import { rootActions, Getters } from '@/store/types'
import ReferralProgramCreateAndUpdateFormItems from '@/components/ReferralProgramCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'

export default {
    components: {
        ReferralProgramCreateAndUpdateFormItems,
        VForm,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    computed: {
        referralProgram () {
            return this.$store.getters[Getters.referral.GET_REFERRAL_PROGRAM](this.id)
        },
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.UPDATE_REFERRAL_PROGRAM, { id: this.id, ...values },
            )
            return res
        },
    },
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "กิจกรรมชวนเพื่อน" } },
    [
      _c(
        "VModal",
        {
          attrs: { title: "สร้างกิจกรรมชวนเพื่อน" },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [
          _c("ReferralProgramCreateForm", {
            on: { success: _vm.onCreateEntityPagination }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "admin-referral-program__referral-program-section--active"
        },
        [
          _vm._v("\n        กิจกรรมที่\n        "),
          _c("a-tag", { attrs: { color: "#f50" } }, [
            _vm._v("\n            ใช้งานอยู่\n        ")
          ]),
          _c("ReferralProgramTable", {
            attrs: {
              "data-source": _vm.activeReferralProgram
                ? [_vm.activeReferralProgram]
                : [],
              "custom-row": _vm.createClickToEditRow
            },
            on: { delete: _vm.onDeleteEntityPagination }
          })
        ],
        1
      ),
      _vm._v("\n    กิจกรรมทั้งหมด\n    "),
      _c(
        "div",
        { staticClass: "manager__top-bar" },
        [
          _c("a-input-search", {
            staticStyle: { width: "400px", "margin-right": "18px" },
            attrs: { placeholder: "ค้นหา" },
            on: { change: _vm.handleQueryChange }
          }),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.onOpenCreateForm } },
            [_vm._v("\n            สร้าง\n        ")]
          )
        ],
        1
      ),
      _vm.newEntities.length > 0
        ? _c(
            "SectionNew",
            [
              _c("ReferralProgramTable", {
                attrs: {
                  "data-source": _vm.newEntities,
                  loading: _vm.loading,
                  "custom-row": _vm.createClickToEditRow
                },
                on: { delete: _vm.onDeleteEntityPagination }
              })
            ],
            1
          )
        : _vm._e(),
      _c("ReferralProgramTable", {
        attrs: {
          "data-source": _vm.dataSource,
          loading: _vm.loading,
          "custom-row": _vm.createClickToEditRow
        },
        on: { delete: _vm.onDeleteEntityPagination }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
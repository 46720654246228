<template>
    <a-card
        hoverable="hoverable"
        class="field-card">
        <template slot="cover">
            <a-popconfirm
                title="ยืนยันที่จะลบ ?"
                @visibleChange="(visible)=>setVisible(visible)"
                @confirm="() => deleteField()">
                <span
                    class="field-card__close-button"
                    :class="{ 'active': isPopupVisible() }"
                    @click.stop>
                    <a-icon type="close" />
                </span>
            </a-popconfirm>
            <div class="field-card__cover-container">
                <div
                    v-if="stadium.coverPhotoUrl"
                    class="field-card__cover-img"
                    :style="{ backgroundImage: `url('${getImgUrl (stadium.coverPhotoUrl)}')` }" />
                <div
                    v-else
                    class="field-card__cover-img"
                    :style="{ backgroundImage: `url('${stadiumPlaceholder}')` }" />
            </div>
        </template>
        <a-card-meta :title="stadium.name">
            <a-avatar
                slot="avatar"
                :src="sportIcon"
                size="large" />
            <div
                slot="description"
                class="field-card__desc">
                <template v-if="stadium.description">
                    {{ stadium.description }}
                </template>
                &nbsp;
            </div>
        </a-card-meta>
    </a-card>
</template>
<script>
import { rootActions } from '@/store/types'
import footballIcon from '@/assets/Football.png'
import stadiumPlaceholder from '@/assets/FieldCardPlaceholder.png'

export default {
    props: {
        stadium: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            popupVisible: false,
            sportIcon: footballIcon,
            stadiumPlaceholder,
        }
    },
    methods: {
        deleteField () {
            this.$store.dispatch(rootActions.DELETE_STADIUM, this.stadium.id)
        },
        setVisible (visible) {
            this.popupVisible = visible
        },
        isPopupVisible () {
            return this.popupVisible
        },
        getImgUrl (relativeImgUrl) {
            return relativeImgUrl || ''
        },
    },
}
</script>
<style lang="stylus">
.field-card__desc
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.field-card
    .ant-card-cover
        position: relative
        overflow: hidden

        .field-card__cover-container
            position: relative
            width: 100%
            padding-bottom: 56.25%
            overflow: hidden

    .ant-card-body
        border-top 1px solid $border-color

.field-card__cover-img
    position: absolute
    width: 100%
    height: 100%
    background-size: cover
    background-repeat: no-repeat

.field-card__close-button
    font-size: 24px
    line-height: 0
    padding: $padding = 8px
    position: absolute
    right: 0px
    top: -(@font-size + ($padding * 2))
    z-index: 100
    color: white
    width: auto
    background-color: rgba(0, 0, 0, 0.7)
    transition: all 0.2s
    display: inline-block

    &:hover
        color: $primary-color

.field-card__close-button.active
    color: $primary-color
    top: 0px

.field-card:hover
    .field-card__close-button
        top: 0px
</style>

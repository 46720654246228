var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ชื่อ"
          }
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "name",
                  {
                    rules: [
                      { required: true, message: "กรุณากรอก ชื่อ ด้วยค่ะ" }
                    ]
                  }
                ],
                expression:
                  "[\n                'name',\n                { rules:\n                    [\n                        { required: true, message: 'กรุณากรอก ชื่อ ด้วยค่ะ' },\n                    ]\n                }\n            ]"
              }
            ],
            attrs: { placeholder: "ใช้สำหรับแสดงผล" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "รายละเอียด"
          }
        },
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["desc"],
                expression: "[ 'desc' ]"
              }
            ],
            attrs: {
              placeholder: "รายละเอียดของสินค้า",
              "auto-size": { minRows: 2, maxRows: 6 }
            }
          })
        ],
        1
      ),
      _c("BundleItemsFormItems", { attrs: { data: _vm.data, form: _vm.form } }),
      _c("VFormItemItemCondition", {
        attrs: {
          "is-buy-condition": true,
          data: _vm.data,
          form: _vm.form,
          "parent-name-prop": "buyCondition"
        }
      }),
      _c("VFormItemAmountWithUnlimitOption", {
        attrs: {
          form: _vm.form,
          data: _vm.data,
          label: "จำนวน",
          "name-prop": "totalImportToStock"
        }
      }),
      _c("VFormItemAmountWithUnlimitOption", {
        attrs: {
          form: _vm.form,
          data: _vm.data,
          label: "จำนวนสิทธิ/1คน",
          "name-prop": "buyAmountLimitPerPerson",
          "initial-value": 1
        }
      }),
      _c(
        "div",
        [
          _c(
            "a-form-item",
            {
              attrs: {
                label: "เปิดให้ซื้อได้",
                "label-col": { span: 4 },
                "wrapper-col": { span: 12 }
              }
            },
            [
              _c("a-switch", {
                attrs: { checked: _vm.isPublic },
                on: { change: _vm.onToggleIsPublic }
              }),
              _c("VFormItemRangeDateTime", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isPublic,
                    expression: "isPublic"
                  }
                ],
                attrs: {
                  data: _vm.data,
                  form: _vm.form,
                  "name-prop-start-time": _vm.namePropStartTime,
                  "name-prop-end-time": _vm.namePropEndTime,
                  required: _vm.isPublic
                }
              })
            ],
            1
          ),
          _c("ProductVariantFormItems", {
            attrs: {
              form: _vm.form,
              data: _vm.data,
              "is-update-form": _vm.isUpdateForm
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
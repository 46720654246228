var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "field-card", attrs: { hoverable: "hoverable" } },
    [
      _c(
        "template",
        { slot: "cover" },
        [
          _c(
            "a-popconfirm",
            {
              attrs: { title: "ยืนยันที่จะลบ ?" },
              on: {
                visibleChange: function(visible) {
                  return _vm.setVisible(visible)
                },
                confirm: function() {
                  return _vm.deleteField()
                }
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "field-card__close-button",
                  class: { active: _vm.isPopupVisible() },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                },
                [_c("a-icon", { attrs: { type: "close" } })],
                1
              )
            ]
          ),
          _c("div", { staticClass: "field-card__cover-container" }, [
            _vm.stadium.coverPhotoUrl
              ? _c("div", {
                  staticClass: "field-card__cover-img",
                  style: {
                    backgroundImage:
                      "url('" + _vm.getImgUrl(_vm.stadium.coverPhotoUrl) + "')"
                  }
                })
              : _c("div", {
                  staticClass: "field-card__cover-img",
                  style: {
                    backgroundImage: "url('" + _vm.stadiumPlaceholder + "')"
                  }
                })
          ])
        ],
        1
      ),
      _c(
        "a-card-meta",
        { attrs: { title: _vm.stadium.name } },
        [
          _c("a-avatar", {
            attrs: { slot: "avatar", src: _vm.sportIcon, size: "large" },
            slot: "avatar"
          }),
          _c(
            "div",
            {
              staticClass: "field-card__desc",
              attrs: { slot: "description" },
              slot: "description"
            },
            [
              _vm.stadium.description
                ? [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.stadium.description) +
                        "\n            "
                    )
                  ]
                : _vm._e(),
              _vm._v("\n             \n        ")
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <VPage title="กีฬา">
        <VModal
            v-model="visibleUpdateForm"
            title="Update Sport type">
            <SportUpdateForm :sport-id="selectedEntityId" @success="closeUpdateForm" />
        </VModal>
        <VModal
            v-model="visibleCreateForm"
            title="Create Sport type">
            <SportCreateForm @success="closeCreateForm" />
        </VModal>
        <a-row type="flex" justify="center">
            <a-col :sm="{ span: 24 }" :xxl="{ span: 14 }">
                <div class="stadium-fields__top-bar">
                    <a-button
                        type="primary"
                        class="stadium-holiday__add-button"
                        @click="openCreateForm">
                        Create
                    </a-button>
                </div>
                <VTable
                    :enable-scroll="false"
                    :columns="columns"
                    :data-source="dataSource"
                    :loading="loading"
                    :custom-row="createClickToEditRow">
                    <template
                        v-slot:operation="{ record }">
                        <a-popconfirm
                            v-if="dataSource.length"
                            title="ยืนยันการลบ?"
                            @confirm="() => onDelete(record.id)">
                            <a href="javascript:;" @click.stop>Delete</a>
                        </a-popconfirm>
                    </template>
                </VTable>
            </a-col>
        </a-row>
    </VPage>
</template>
<script>
import VTable from '@/components/VTable.vue'
import VModal from '@/components/VModal.vue'
import VPage from '@/components/VPage.vue'
import EntitiesPage from '@/mixins/EntitiesPage'
import { rootActions } from '@/store/types'
import SportCreateForm from '@/components/SportCreateForm.vue'
import SportUpdateForm from '@/components/SportUpdateForm.vue'

export default {
    components: {
        VTable,
        VModal,
        VPage,
        SportCreateForm,
        SportUpdateForm,
    },
    mixins: [EntitiesPage],
    data () {
        return {
            columns: [
                {
                    title: 'Icon',
                    dataIndex: 'iconUrl',
                    scopedSlots: { customRender: 'icon' },
                    width: '200px',
                },
                {
                    title: 'Name',
                    dataIndex: 'displayName',
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'Operation',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
        }
    },
    methods: {
        fetchEntities () {
            return this.$store.dispatch(rootActions.LOAD_SPORTS)
        },
        getEntities () {
            return this.$store.state.stadium.sports
        },
        async delete (id) {
            return this.$store.dispatch(rootActions.DELETE_SPORT, id)
        },
    },
}
</script>
<style lang="stylus">
.stadium-fields__top-bar
    margin-bottom 24px
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        { attrs: { label: "ชื่อกิจกรรม" } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "title",
                  {
                    rules: [{ required: true, message: "กรุณาใส่ชื่อด้วยค่ะ" }]
                  }
                ],
                expression:
                  "\n                [ 'title',\n                  { rules:\n                      [\n                          { required: true,\n                            message: 'กรุณาใส่ชื่อด้วยค่ะ', } ] } ]"
              }
            ]
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: "คำบรรยายเบื้องต้น" } },
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["desc"],
                expression: "[ 'desc' ]"
              }
            ],
            attrs: { "auto-size": { minRows: 2, maxRows: 6 } }
          })
        ],
        1
      ),
      _c("ReferralPromotionPickerFormItem", {
        attrs: {
          form: _vm.form,
          data: _vm.data,
          "prop-name": _vm.referralPropName,
          label: "รางวัลสำหรับคนถูกชวน"
        }
      }),
      _vm.selectedReferralPromotion()
        ? _c(
            "a-form-item",
            { attrs: { label: "รายละเอียด" } },
            [
              _c("ReferralPromotion", {
                attrs: { "referral-promotion": _vm.selectedReferralPromotion() }
              })
            ],
            1
          )
        : _vm._e(),
      _c("ReferralPromotionPickerFormItem", {
        attrs: {
          form: _vm.form,
          data: _vm.data,
          "prop-name": _vm.referrerPropName,
          label: "รางวัลสำหรับคนชวน"
        }
      }),
      _vm.selectedReferrerPromotion()
        ? _c(
            "a-form-item",
            { attrs: { label: "รายละเอียด" } },
            [
              _c("ReferralPromotion", {
                attrs: { "referral-promotion": _vm.selectedReferrerPromotion() }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
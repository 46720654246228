<template>
    <a-form-item
        :label="label"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 12 }">
        <div>
            <a-radio-group
                v-model="isLimited"
                @change="onChangeLimitedMode">
                <a-radio :value="true">
                    <span v-show="isLimited">
                        <a-input-number
                            v-decorator="
                                [ [ nameProp ] ,
                                  {
                                      ...initialValue !== null ?
                                          { initialValue: initialValue } : {},
                                      rules:
                                          [
                                              { required: true,
                                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', }
                                          ] } ]"
                            :min="1" />
                    </span>
                </a-radio>
                <a-radio :value="false">
                    ไม่จำกัด
                </a-radio>
            </a-radio-group>
        </div>
    </a-form-item>
</template>
<script>
import FormItems from '@/mixins/FormItems'

export default {
    mixins: [FormItems],
    props: {
        label: {
            type: String,
            required: true,
        },
        nameProp: {
            type: String,
            required: true,
        },
        initialValue: {
            type: Number,
            default: null,
        },
    },
    data () {
        return {
            isLimited: true,
        }
    },
    methods: {
        onChangeLimitedMode (event) {
            this.isLimited = event.target.value
            this.form.setFieldsValue({
                [this.nameProp]: this.isLimited ? 1 : -1,
            })
        },
        mapDataToFormItems (data) {
            const propValue = data[this.nameProp]
            this.isLimited = propValue !== -1
            return {
                [this.nameProp]: propValue,
            }
        },
    },
}
</script>

<template>
    <div>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="รหัส ส่วนลด">
            <a-input
                v-decorator="
                    [ 'code',
                      {
                          normalize: normallizeCode,
                          rules:
                              [
                                  { required: true,
                                    message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]" />
        </a-form-item>
        <DiscountCreateAndUpdateFormItems :form="form" :data="data" />
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import DiscountCreateAndUpdateFormItems from '@/components/DiscountCreateAndUpdateFormItems.vue'

export default {
    components: {
        DiscountCreateAndUpdateFormItems,
    },
    mixins: [FormItems],
    methods: {
        mapDataToFormItems (data) {
            const {
                code,
            } = data
            return {
                code,
            }
        },
        normallizeCode (allValues) {
            return allValues ? allValues.toUpperCase() : ''
        },
    },
}
</script>
<style lang="stylus">
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isBuyCondition
        ? _c(
            "a-form-item",
            {
              attrs: {
                label: "ราคาขั้นต่ำ",
                "label-col": { span: 4 },
                "wrapper-col": { span: 12 }
              }
            },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [_vm.getFullNameProp("minPrice")],
                    expression:
                      "\n                [ getFullNameProp('minPrice') ]"
                  }
                ],
                attrs: { min: 1 }
              }),
              _vm._v(
                "\n        = " +
                  _vm._s(
                    _vm._f("formatCurrency")(
                      _vm.form.getFieldValue(_vm.getFullNameProp("minPrice"))
                    )
                  ) +
                  "\n    "
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-form-item",
        {
          attrs: {
            label: " เฉพาะลูกค้าใหม่",
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c("a-switch", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  _vm.getFullNameProp("isOnlyNewUser"),
                  { valuePropName: "checked", initialValue: false }
                ],
                expression:
                  "\n                [ getFullNameProp('isOnlyNewUser'),\n                  { valuePropName: 'checked',\n                    initialValue: false },\n                ]"
              }
            ]
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <VModal
            v-model="visibleUpdateForm"
            title="แก้ไขแพ๊กเกจคูปอง">
            <BundleCouponUpdateForm
                :id="selectedEntityId"
                @success="closeUpdateForm" />
        </VModal>
        <VTable
            :data-source="dataSource"
            :columns="columns"
            :loading="loading"
            :custom-row="createClickToEditRow">
            <template
                v-slot:operation="{ record }">
                <a-popconfirm
                    v-if="dataSource.length"
                    title="ยืนยันการลบ?"
                    @confirm="onConfirmDelete(record)">
                    <a href="javascript:;" @click.stop>ลบ</a>
                </a-popconfirm>
            </template>
        </VTable>
    </div>
</template>
<script>
import MixinTable from '@/mixins/MixinTable'
import { PromotionCodeType } from '@/utils/enum'
import BundleCouponUpdateForm from '@/components/BundleCouponUpdateForm.vue'
import VModal from '@/components/VModal.vue'
import VTable from '@/components/VTable.vue'

export default {
    components: {
        BundleCouponUpdateForm,
        VModal,
        VTable,
    },
    mixins: [MixinTable],
    props: {
        promotionCodeType: {
            type: String,
            required: true,
        },
        expireByDuration: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            PromotionCodeType,
            operationColumns: [
                {
                    title: 'การจัดการ',
                    dataIndex: 'operation',
                    width: 100,
                    fixed: 'right',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
            baseColumns: [
                {
                    title: 'ชื่อ',
                    dataIndex: 'name',
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'ราคา',
                    width: 150,
                    dataIndex: 'variants[0][minPrice]',
                    scopedSlots: { customRender: 'currency' },
                },
                {
                    title: 'จำนวนที่มี',
                    dataIndex: 'totalImportToStock',
                    width: 150,
                    scopedSlots: { customRender: 'amount' },
                },
                {
                    title: 'จำนวนที่ซื้อไปแล้ว',
                    dataIndex: 'noSoldItem',
                    width: 150,
                    scopedSlots: { customRender: 'text' },
                },
            ],
        }
    },
    computed: {
        columns () {
            return [
                ...this.baseColumns,
                ...this.operationColumns,
            ]
        },
    },
}
</script>

import { includeText } from '@/utils/utils'
import { PromotionCodeType } from '@/utils/enum'

export default {
    data () {
        return {
            PromotionCodeType,
        }
    },
    methods: {
        search (query) {
            if (!query) return [...this.entities]
            return this.entities.filter(
                (item) => includeText(item.code, query)
                            || includeText(item.name, query)
                            || includeText(item.discountValue, query)
                            || (item.date && includeText(item.date.format('DD-MM-YYYY'), query)),
            )
        },
    },
}

export default {
    data () {
        return {
            page: 1,
            size: 16,
            total: this.size,
            entityIds: [],
        }
    },
}

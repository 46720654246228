<template>
    <VPage title="คูปองส่วนลด">
        <VModal
            v-model="visibleCreateForm"
            title="สร้างแพ็กเกจคูปองส่วนลด">
            <BundleCouponCreateForm @success="onCreateEntityPagination" />
        </VModal>
        <div class="manager__top-bar">
            <a-input-search
                placeholder="ค้นหา"
                style="width: 400px;margin-right:18px;"
                @change="handleQueryChange" />
            <a-button
                type="primary"
                @click="openCreateForm">
                สร้าง
            </a-button>
        </div>
        <SectionNew v-if="newEntities.length > 0">
            <BundleCouponTable
                :promotion-code-type="PromotionCodeType.COUPON"
                :data-source="newEntities"
                :loading="loading"
                :custom-row="createClickToEditRow"
                @delete="onDeleteEntityPagination" />
        </SectionNew>
        <BundleCouponTable
            :promotion-code-type="PromotionCodeType.COUPON"
            :data-source="dataSource"
            :loading="loading"
            :custom-row="createClickToEditRow"
            @delete="onDeleteEntityPagination" />
    </VPage>
</template>
<script>
import EntitiesPagination from '@/mixins/EntitiesPagination'
import MixinDiscount from '@/mixins/MixinDiscount'
import MixinDiscountPagination from '@/mixins/MixinDiscountPagination'
import { rootActions } from '@/store/types'
import BundleCouponCreateForm from '@/components/BundleCouponCreateForm.vue'
import VModal from '@/components/VModal.vue'
import VPage from '@/components/VPage.vue'
import BundleCouponTable from '@/components/BundleCouponTable.vue'
import SectionNew from '@/components/SectionNew.vue'

export default {
    components: {
        SectionNew,
        BundleCouponTable,
        BundleCouponCreateForm,
        VModal,
        VPage,
    },
    mixins: [EntitiesPagination, MixinDiscount, MixinDiscountPagination],
    methods: {
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_BUNDLE_COUPONS, { page, size },
            )
            return res
        },
        getAllEntities () {
            return this.$store.state.shop.bundleCoupons
        },
        async delete (id) {
            await this.$store.dispatch(rootActions.DELETE_BUNDLE_COUPON, id)
        },
        async onOpenCreateForm () {
            this.openCreateForm()
        },
    },
}
</script>

import EntitiesPagination from '@/mixins/EntitiesPagination'
import { rootActions } from '@/store/types'

export default {
    mixins: [EntitiesPagination],
    methods: {
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_REFERRAL_PROMOTIONS, { page, size },
            )
            return res
        },
        getAllEntities () {
            return this.$store.state.referral.referralPromotions
        },
        async delete (id) {
            await this.$store.dispatch(rootActions.DELETE_REFERRAL_PROMOTION, id)
        },
        async onOpenCreateForm () {
            this.openCreateForm()
        },
    },
}

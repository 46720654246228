<template>
    <div>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ก๊วน">
            <a-select
                v-decorator="[ 'partyId',{ normalize: normalizePartyId } ]"
                allow-clear
                show-search
                placeholder="เลือกก๊วน"
                option-filter-prop="children"
                style="width: 240px;"
                :filter-option="filterOption"
                :loading="isLoadPartys"
                @focus="loadPartys">
                <a-select-option
                    v-for="party in partys"
                    :key="party.id"
                    :value="party.id">
                    {{ party.name }}
                </a-select-option>
            </a-select>
        </a-form-item>
    </div>
</template>

<script>
import { rootActions } from '@/store/types'
import FormItems from '@/mixins/FormItems'
import MixinCreateFormModal from '@/mixins/MixinCreateFormModal'
import MixinUpdateFormModal from '@/mixins/MixinUpdateFormModal'

export default {
    mixins: [FormItems, MixinCreateFormModal, MixinUpdateFormModal],
    data () {
        return {
            isLoadPartys: false,
        }
    },
    computed: {
        partys () {
            return this.$store.state.partys.partys
        },
    },
    beforeMount () {
        this.loadPartys()
    },
    methods: {
        async loadPartys () {
            this.isLoadPartys = true
            await this.$store.dispatch(
                rootActions.LOAD_PARTYS,
                { page: 1, size: 200 },
            )
            this.isLoadPartys = false
        },
        filterOption (input, option) {
            return (
                option.componentOptions.children[0]
                    .text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
        mapDataToFormItems (data) {
            return {
                partyId: data.partyId,
            }
        },
        normalizePartyId (value) {
            return value || null
        },
    },
}
</script>

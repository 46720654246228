<template>
    <VForm
        v-slot="{ form }"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <DiscountCreateAndUpdateFormItems :form="form" />
    </VForm>
</template>
<script>
import { rootActions } from '@/store/types'
import VForm from '@/components/VForm.vue'
import DiscountCreateAndUpdateFormItems from '@/components/DiscountCreateAndUpdateFormItems.vue'

export default {
    components: {
        VForm,
        DiscountCreateAndUpdateFormItems,
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.ADD_DISCOUNT_COUPON, { ...values },
            )
            return res
        },
    },
}
</script>

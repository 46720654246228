var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VPage", { attrs: { title: "กิจกรรม รับเงินคืน(Lenkila Coin)" } }, [
    _c(
      "div",
      {
        staticClass: "admin-cashback-program__cashback-program-section--active"
      },
      [
        _vm.activeCashbackProgramId != null ||
        _vm.activeCashbackProgramId != undefined
          ? _c("CashbackProgramUpdateForm", {
              attrs: { id: _vm.activeCashbackProgramId }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v(_vm._s(_vm.label))]),
      _vm._t("tooltip"),
      _c("br"),
      _vm._l(_vm.form.getFieldValue("keys"), function(k, index) {
        return _c(
          "a-form-item",
          _vm._b(
            {
              key: k,
              attrs: {
                "label-col": { span: 4 },
                "wrapper-col": { span: 12 },
                label: index + "",
                required: false
              }
            },
            "a-form-item",
            _vm.formItemLayout,
            false
          ),
          [
            _c(
              "a-card",
              [
                k >= _vm.minLength
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "ยืนยันการลบ?" },
                        on: {
                          confirm: function() {
                            return _vm.remove(index)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "product-variant-form-items__remove-button",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    ลบ\n                    "
                            ),
                            k >= _vm.minLength
                              ? _c("a-icon", {
                                  staticClass: "dynamic-delete-button",
                                  style: { fontSize: "16px" },
                                  attrs: { type: "delete" }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _c("br"),
                _vm._t("default", null, { item: _vm.getItem(index) })
              ],
              2
            ),
            _c("a-button", {
              attrs: { icon: "up", disabled: index === 0 },
              on: {
                click: function($event) {
                  return _vm.onClickUp(index)
                }
              }
            }),
            _c("a-button", {
              attrs: {
                icon: "down",
                disabled: index + 1 >= _vm.form.getFieldValue("keys").length
              },
              on: {
                click: function($event) {
                  return _vm.onClickDown(index)
                }
              }
            })
          ],
          1
        )
      }),
      _c(
        "a-form-item",
        _vm._b({}, "a-form-item", _vm.formItemLayoutWithOutLabel, false),
        [
          _c(
            "a-button",
            {
              staticStyle: { width: "60%" },
              attrs: { type: "dashed" },
              on: { click: _vm.onClickAdd }
            },
            [
              _c("a-icon", { attrs: { type: "plus" } }),
              _vm._v(" " + _vm._s(_vm.addButtonLabel) + "\n        ")
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
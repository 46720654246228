<template>
    <div class="stadium-boosts">
        <a-tabs default-active-key="1">
            <a-tab-pane key="1">
                <span slot="tab">
                    <a-icon type="double-right" />
                    กำลังจะมาถึง
                </span>
                <BannerMediasVue
                    :is-past="false"
                    :disable-open-update-form-on-click="disableOpenUpdateFormOnClick"
                    @clickItem="(value)=>this.$emit('clickItem',value)" />
            </a-tab-pane>
            <a-tab-pane key="2">
                <span slot="tab">
                    <a-icon type="history" />
                    ประวัติ
                </span>
                <BannerMediasVue
                    :is-past="true"
                    :disable-open-update-form-on-click="disableOpenUpdateFormOnClick"
                    @clickItem="(value)=>this.$emit('clickItem',value)" />
            </a-tab-pane>
        </a-tabs>
    </div>
</template>
<script>
import BannerMediasVue from '@/components/BannerMedias.vue'

export default {
    components: {
        BannerMediasVue,
    },
    props: {
        disableOpenUpdateFormOnClick: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style lang="stylus">
.admin-banner-medias__top-bar
    margin-bottom 24px
    button
        margin-left 8px
</style>

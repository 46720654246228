var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "เงื่อนไข ใช้เงินคืน(Lenkila Coin)" } },
    [
      _c(
        "div",
        {
          staticClass:
            "admin-cashback-program__cashback-program-section--active"
        },
        [
          _vm.activeApplicableCashbackProgramId != null ||
          _vm.activeApplicableCashbackProgramId != undefined
            ? _c("ApplicableCashbackProgramUpdateForm", {
                attrs: { id: _vm.activeApplicableCashbackProgramId }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DiscountTable", {
        attrs: {
          "expire-by-duration": true,
          "promotion-code-type": _vm.PromotionCodeType.COUPON,
          "data-source": _vm.dataSource,
          loading: _vm.loading,
          "custom-row": _vm.createClickToEditRow,
          pagination: _vm.pagination
        },
        on: {
          change: _vm.handleTableChange,
          delete: function(value) {
            return this$1.$emit("delete", value)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "updateForm",
            fn: function(ref) {
              var selectedEntityId = ref.selectedEntityId
              var onSuccess = ref.onSuccess
              return [
                _c("CouponForBundleUpdateForm", {
                  attrs: { id: selectedEntityId },
                  on: { success: onSuccess }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
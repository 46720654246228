var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stadium-boosts" },
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "1" } },
        [
          _c(
            "a-tab-pane",
            { key: "1" },
            [
              _c(
                "span",
                { attrs: { slot: "tab" }, slot: "tab" },
                [
                  _c("a-icon", { attrs: { type: "double-right" } }),
                  _vm._v("\n                กำลังจะมาถึง\n            ")
                ],
                1
              ),
              _c("BannerMediasVue", {
                attrs: {
                  "is-past": false,
                  "disable-open-update-form-on-click":
                    _vm.disableOpenUpdateFormOnClick
                },
                on: {
                  clickItem: function(value) {
                    return this$1.$emit("clickItem", value)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2" },
            [
              _c(
                "span",
                { attrs: { slot: "tab" }, slot: "tab" },
                [
                  _c("a-icon", { attrs: { type: "history" } }),
                  _vm._v("\n                ประวัติ\n            ")
                ],
                1
              ),
              _c("BannerMediasVue", {
                attrs: {
                  "is-past": true,
                  "disable-open-update-form-on-click":
                    _vm.disableOpenUpdateFormOnClick
                },
                on: {
                  clickItem: function(value) {
                    return this$1.$emit("clickItem", value)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="Info">
                <VForm
                    v-slot="{ form,data }"
                    :data="stadium"
                    :on-submit-async="submit"
                    @success="(value)=>this.$emit('success',value)">
                    <StadiumCreateAndUpdateFormItems
                        :data="data"
                        :form="form" />
                </VForm>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Employee">
                <Employees :stadium-id="stadiumId" :admin-mode="true" />
            </a-tab-pane>
            <a-tab-pane key="3" tab="Settings">
                <VForm
                    v-slot="{ form,data }"
                    :data="stadium"
                    :on-submit-async="submit"
                    @success="(value)=>this.$emit('success',value)">
                    <StadiumAdminSettingCreateAndUpdateFormItems
                        :data="data"
                        :form="form" />
                </VForm>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>
<script>
import StadiumCreateAndUpdateFormItems from '@/components/StadiumCreateAndUpdateFormItems.vue'
import StadiumAdminSettingCreateAndUpdateFormItems from '@/components/StadiumAdminSettingCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'
import Employees from '@/views/Employees.vue'
import { rootActions, Getters } from '@/store/types'

export default {
    components: {
        StadiumAdminSettingCreateAndUpdateFormItems,
        StadiumCreateAndUpdateFormItems,
        Employees,
        VForm,
    },
    props: {
        stadiumId: {
            type: String,
            required: true,
        },
    },
    computed: {
        stadium () {
            return this.$store.getters[Getters.stadium.GET_STADIUM](this.stadiumId)
        },
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.UPDATE_STADIUM, { id: this.stadium.id, ...values },
            )
            return res
        },
    },
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        { attrs: { label: "ชื่อที่ใช้แสดง" } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "displayName",
                  {
                    rules: [
                      { required: true, message: "กรุณาใส่ค่าในช่องนี้ด้วยค่ะ" }
                    ]
                  }
                ],
                expression:
                  "\n                [ 'displayName',\n                  { rules:\n                      [\n                          { required: true,\n                            message: 'กรุณาใส่ค่าในช่องนี้ด้วยค่ะ', } ] } ]"
              }
            ]
          })
        ],
        1
      ),
      _c("VFormItemLogo", {
        attrs: {
          form: _vm.form,
          data: _vm.data,
          label: "ไอคอน (Icon)",
          "prop-name": "iconUrl"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
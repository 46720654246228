<template>
    <div>
        <DiscountCreateAndUpdateFormItems
            :referral-mode="true"
            :form="form"
            :data="data" />
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import DiscountCreateAndUpdateFormItems from '@/components/DiscountCreateAndUpdateFormItems.vue'

export default {
    components: {
        DiscountCreateAndUpdateFormItems,
    },
    mixins: [FormItems],
    methods: {
        mapDataToFormItems () {
            return {}
        },
    },
}
</script>
<style lang="stylus">
</style>

<template>
    <a-form-item
        class="switch switch--disable"
        label="ระงับการใช้งาน"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 12 }">
        <a-switch
            v-decorator="
                [ `disabled` ,
                  { valuePropName: 'checked',
                    initialValue: false },
                ]"
            checked-children="ระงับ" />
    </a-form-item>
</template>
<script>
import FormItems from '@/mixins/FormItems'

export default {
    mixins: [FormItems],
    methods: {
        mapDataToFormItems (data) {
            return {
                disabled: data.disabled,
            }
        },
    },
}
</script>

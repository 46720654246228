<template>
    <VForm
        v-slot="{ form }"
        class="manager-create-form"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <UserCreateAndUpdateFormItems :form="form" :is-create-form="true" />
        <PeopleCreateAndUpdateFormItems :form="form" />
        <ManagerCreateAndUpdateFormItems :form="form" />
    </VForm>
</template>
<script>
import { rootActions } from '@/store/types'
import VForm from '@/components/VForm.vue'
import UserCreateAndUpdateFormItems from '@/components/UserCreateAndUpdateFormItems.vue'
import PeopleCreateAndUpdateFormItems from '@/components/PeopleCreateAndUpdateFormItems.vue'
import ManagerCreateAndUpdateFormItems from '@/components/ManagerCreateAndUpdateFormItems.vue'

export default {
    components: {
        VForm,
        PeopleCreateAndUpdateFormItems,
        UserCreateAndUpdateFormItems,
        ManagerCreateAndUpdateFormItems,
    },
    data () {
        return {
            createdEntity: null,
        }
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.ADD_MANAGER, { ...values },
            )
            return res
        },
    },
}
</script>

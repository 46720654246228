var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModal",
        {
          attrs: { title: _vm.updateFormTitle },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _vm._t(
            "updateForm",
            function() {
              return [
                _vm.promotionCodeType === _vm.PromotionCodeType.COUPON
                  ? _c("CouponUpdateForm", {
                      attrs: { id: _vm.selectedEntityId },
                      on: { success: _vm.closeUpdateForm }
                    })
                  : _c("DiscountCodeUpdateForm", {
                      attrs: { id: _vm.selectedEntityId },
                      on: { success: _vm.closeUpdateForm }
                    })
              ]
            },
            {
              selectedEntityId: _vm.selectedEntityId,
              onSuccess: _vm.closeUpdateForm
            }
          )
        ],
        2
      ),
      _c("VTable", {
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          loading: _vm.loading,
          pagination: _vm.pagination,
          "custom-row": _vm.createClickToEditRow,
          "row-class-name": _vm.rowClassName
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "discountType",
            fn: function(ref) {
              var text = ref.text
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("formatDiscountTypeEnum")(text)) +
                    "\n        "
                )
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var record = ref.record
              return [
                _vm.dataSource.length
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "ยืนยันการลบ?" },
                        on: {
                          confirm: function($event) {
                            return _vm.onConfirmDelete(record)
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [_vm._v("ลบ")]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
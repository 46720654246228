<template>
    <div>
        <VFormItemRangeDateTimeVue :data="data" :form="form" />
        <VFormItemPhotoVue
            :data="data"
            :form="form"
            label="รูป Banner"
            prop-name="imageUrl"
            :width="ImageSize.w "
            :height="ImageSize.h " />
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ลิงค์">
            <a-input
                v-decorator="
                    [ 'linkUrl',{ rules:
                        [
                            { validator: urlValidator },
                        ] } ]" />
        </a-form-item>
        <a-form-item
            label="เปิด Ad"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-switch
                :checked="isAd"
                @change="onToggleIsAd" />
        </a-form-item>
        <div v-if="isAd">
            <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="ชื่อ Ad">
                <a-input
                    v-decorator="
                        [ 'gaAdUnitName',
                          { rules:
                              [
                                  { required: true,
                                    message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
                    placeholder="ex Adidas New Year Sale" />
            </a-form-item>
            <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
                label="เจ้าของ Ad">
                <a-input
                    v-decorator="
                        [ 'gaAdSource',
                          { rules:
                              [
                                  { required: true,
                                    message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
                    placeholder="ex Adidas, Nike" />
            </a-form-item>
            <a-form-item
                label="ราคา/การมองเห็น"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }">
                <a-input-number
                    v-decorator="
                        [ `gaValue` ,
                          { rules:
                              [
                                  { required: true,
                                    message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', } ] } ]"
                    :min="0" />
                = {{ form.getFieldValue(`gaValue`) | formatCurrency }}
            </a-form-item>
        </div>

    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import VFormItemRangeDateTimeVue from '@/components/VFormItemRangeDateTime.vue'
import VFormItemPhotoVue from '@/components/VFormItemPhoto .vue'
import { EntityImageSize } from '@/utils/enum'
import { isEmptyString, isValidUrl } from '@/utils/utils'

export default {
    components: {
        VFormItemRangeDateTimeVue,
        VFormItemPhotoVue,
    },
    mixins: [FormItems],
    data () {
        return {
            ImageSize: EntityImageSize.BANNER,
            isAd: this.data !== null && this.data.gaAdUnitName !== null,
            isValidUrl,
        }
    },
    methods: {
        mapDataToFormItems (data) {
            const {
                start,
                end,
                gaAdPlatform,
                gaAdSource,
                gaAdFormat,
                gaAdUnitName,
                gaCurrency,
                gaValue,
                linkUrl,
            } = data
            return {
                start,
                end,
                gaAdPlatform,
                gaAdSource,
                gaAdFormat,
                gaAdUnitName,
                gaCurrency,
                gaValue,
                linkUrl,
            }
        },
        onToggleIsAd (value) {
            this.isAd = value
        },
        urlValidator (rule, value, callback) {
            if (isEmptyString(value) || this.isValidUrl(value)) {
                callback()
            } else {
                callback('ต้องอยู่ในรูปแบบ http://xxxxxxxxx หรือ https://xxxxxxxxx')
            }
        },
    },
}
</script>

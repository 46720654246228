var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        {
          attrs: {
            label: "สนามจดทะเบียนภาษีมูลค่าเพิ่ม (VAT)",
            "label-col": { span: 6 },
            "wrapper-col": { span: 10 }
          }
        },
        [
          _c("a-switch", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "mainTaxInfo.hasVAT",
                  { valuePropName: "checked", initialValue: false }
                ],
                expression:
                  "\n                [ `mainTaxInfo.hasVAT` ,\n                  { valuePropName: 'checked',\n                    initialValue: false },\n                ]"
              }
            ],
            attrs: { "checked-children": "จด Vat" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            label: "คิดราคารวม VAT",
            "label-col": { span: 6 },
            "wrapper-col": { span: 10 }
          }
        },
        [
          _c("a-switch", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "mainTaxInfo.isIncVAT",
                  { valuePropName: "checked", initialValue: false }
                ],
                expression:
                  "\n                [ `mainTaxInfo.isIncVAT` ,\n                  { valuePropName: 'checked',\n                    initialValue: false },\n                ]"
              }
            ],
            attrs: { "checked-children": "รวม Vat" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            label: "เลขประจำตัวผู้เสียภาษี",
            "label-col": { span: 6 },
            "wrapper-col": { span: 10 }
          }
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["mainTaxInfo.taxNo"],
                expression: "\n                [ 'mainTaxInfo.taxNo' ]"
              }
            ]
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            label: "ชื่อผู้เสียภาษี",
            "label-col": { span: 6 },
            "wrapper-col": { span: 10 }
          }
        },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["mainTaxInfo.name"],
                expression: "\n                [ 'mainTaxInfo.name' ]"
              }
            ]
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ที่อยู่ผู้เสียภาษี"
          }
        },
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["mainTaxInfo.addressString"],
                expression: "[ 'mainTaxInfo.addressString' ]"
              }
            ],
            attrs: {
              placeholder: "บ้านเลขที่ ถนน อำเภอ จังหวัด",
              "auto-size": { minRows: 2, maxRows: 6 }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <VPage title="ผู้จัดการ">
        <VModal
            v-model="visibleCreateForm"
            title="สร้าง ผู้จัดการ">
            <ManagerCreateForm @success="onCreateEntityPagination" />
        </VModal>
        <div class="manager__top-bar">
            <a-input-search
                placeholder="คัดกรองโดย ชื่อ, เบอร์ หรือ อีเมล"
                style="width: 400px;margin-right:18px;"
                @change="handleQueryChange" />
            <a-button
                type="primary"
                @click="openCreateForm">
                สร้าง
            </a-button>
        </div>
        <SectionNew v-show="newEntities.length > 0">
            <TableManagers
                :data-source="newEntities"
                :loading="loading"
                @delete="onDeleteEntityPagination" />
        </SectionNew>
        <TableManagers
            :data-source="dataSource"
            :loading="loading"
            :pagination="pagination"
            @delete="onDeleteEntityPagination"
            @change="handleTableChange" />
    </VPage>
</template>
<script>
import { rootActions } from '@/store/types'
import ManagerCreateForm from '@/components/ManagerCreateForm.vue'
import TableManagers from '@/components/TableManagers.vue'
import VModal from '@/components/VModal.vue'
import VPage from '@/components/VPage.vue'
import VPermission from '@/mixins/VPermission'
import SectionNew from '@/components/SectionNew.vue'
import { includeText } from '@/utils/utils'
import EntitiesPagination from '@/mixins/EntitiesPagination'

export default {
    components: {
        ManagerCreateForm,
        TableManagers,
        SectionNew,
        VModal,
        VPage,
    },
    mixins: [EntitiesPagination, VPermission],
    data () {
        return {
            size: 10,
        }
    },
    methods: {
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_MANAGERS,
                { page, size },
            )
            return res
        },
        getAllEntities () {
            return this.$store.state.manager.managers
        },
        async delete (id) {
            await this.$store.dispatch(rootActions.DELETE_MANAGER, id)
        },
        search (query) {
            if (!query) return [...this.entities]
            return this.entities.filter(
                (item) => includeText(item.firstName, query)
                            || includeText(item.lastName, query)
                            || includeText(item.nickName, query)
                            || includeText(item.tel, query)
                            || includeText(item.email, query),
            )
        },
    },
}
</script>
<style lang="stylus">
.managers__table
    clear both
.manager__top-bar
    margin-bottom: 24px
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VFormItemDisableSwitch", {
        attrs: { form: _vm.form, data: _vm.data }
      }),
      _c(
        "a-form-item",
        {
          attrs: {
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 },
            label: "ประเภทเงินคืน"
          }
        },
        [
          _c(
            "a-radio-group",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "amountType",
                    {
                      initialValue: _vm.CashbackType.EVERY_X_FOR_1
                    }
                  ],
                  expression:
                    "\n                [ 'amountType',\n                  {\n                      initialValue: CashbackType.EVERY_X_FOR_1\n                  }\n                ]"
                }
              ]
            },
            [
              _c(
                "a-radio",
                { attrs: { value: _vm.CashbackType.EVERY_X_FOR_1 } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("formatCashbackTypeEnum")(
                          _vm.CashbackType.EVERY_X_FOR_1
                        )
                      ) +
                      "\n            "
                  )
                ]
              ),
              _c("a-radio", { attrs: { value: _vm.CashbackType.PERCENT } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("formatCashbackTypeEnum")(_vm.CashbackType.PERCENT)
                    ) +
                    "\n            "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("VFormItemNumberWithBar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.form.getFieldValue("amountType") === _vm.CashbackType.PERCENT,
            expression:
              "form.getFieldValue('amountType') === CashbackType.PERCENT"
          }
        ],
        attrs: {
          "name-prop": "cashbackValue",
          form: _vm.form,
          data: _vm.data,
          min: 0,
          max: 100,
          label: "เงินคืน (%)",
          required: true
        }
      }),
      _c(
        "a-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.form.getFieldValue("amountType") ===
                _vm.CashbackType.EVERY_X_FOR_1,
              expression:
                "form.getFieldValue('amountType') === CashbackType.EVERY_X_FOR_1"
            }
          ],
          attrs: {
            label: "ได้ 1 coin ทุกๆ",
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "cashbackValue",
                  {
                    rules: [
                      { required: true, message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ" }
                    ]
                  }
                ],
                expression:
                  "\n                [ `cashbackValue`,\n                  { rules:\n                      [\n                          { required:\n                                true,\n                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },\n                      ] } ]"
              }
            ],
            attrs: { min: 0 }
          }),
          _vm._v(
            "\n        = " +
              _vm._s(
                _vm._f("formatCurrency")(
                  _vm.form.getFieldValue("cashbackValue")
                )
              ) +
              " ≈\n        " +
              _vm._s(
                _vm.form.getFieldValue("cashbackValue")
                  ? Math.floor(
                      (1 / _vm.form.getFieldValue("cashbackValue")) * 100
                    )
                  : "-"
              ) +
              "\n        % cashback\n    "
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.form.getFieldValue("amountType") === _vm.CashbackType.PRICE,
              expression:
                "form.getFieldValue('amountType') === CashbackType.PRICE"
            }
          ],
          attrs: {
            label: "เงินคืน (บาท)",
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "cashbackValue",
                  {
                    rules: [
                      { required: true, message: "กรุณาใส่ค่าช่องนี้ด้วยค่ะ" }
                    ]
                  }
                ],
                expression:
                  "\n                [ `cashbackValue`,\n                  { rules:\n                      [\n                          { required:\n                                true,\n                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },\n                      ] } ]"
              }
            ],
            attrs: { min: 0 }
          }),
          _vm._v(
            "\n        = " +
              _vm._s(
                _vm._f("formatCurrency")(
                  _vm.form.getFieldValue("cashbackValue")
                )
              ) +
              "\n    "
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.form.getFieldValue("amountType") !== _vm.CashbackType.PRICE,
              expression:
                "form.getFieldValue('amountType') !== CashbackType.PRICE"
            }
          ],
          attrs: {
            label: "สูงสุดไม่เกิน (บาท)",
            "label-col": { span: 4 },
            "wrapper-col": { span: 12 }
          }
        },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["maximumCashbackAmount"],
                expression: "\n                [ `maximumCashbackAmount` ]"
              }
            ],
            attrs: { min: 0 }
          }),
          _vm._v(
            "\n        = " +
              _vm._s(
                _vm._f("formatCurrency")(
                  _vm.form.getFieldValue("maximumCashbackAmount")
                )
              ) +
              " / บิล\n    "
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
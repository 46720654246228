<template>
    <VPageVue title="สไลด์ Banner">
        <!-- <VModal
            v-model="visibleUpdateForm"
            :after-close="clearSelectedEntityId"
            title="แก้ไขรายการ Banner">
            <BannerUpdateFormVue
                :id="selectedEntityId"
                @success="closeUpdateForm" />
        </VModal> -->
        <BannerUpdateFormVue v-if="mainBanner" :id="mainBanner.id" />
        <!-- <VList
            v-slot="{ item }"
            :grid="grid"
            :data-source="entities">
            <BannerMediaCard
                :banner-media="item"
                @click.native="openUpdateForm(item.id)" />
        </VList> -->
    </VPageVue>
</template>
<script>
import { rootActions } from '@/store/types'
import BannerUpdateFormVue from '@/components/BannerUpdateForm.vue'
import VPageVue from '@/components/VPage.vue'

export default {
    components: {
        VPageVue,
        BannerUpdateFormVue,
    },
    data () {
        return {
            grid: {
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 1,
                xxl: 1,
            },
        }
    },
    computed: {
        mainBanner () {
            return this.$store.state.banner.banners[0]
        },
    },
    async created () {
        await this.$store.dispatch(rootActions.LOAD_MAIN_BANNER)
    },
}
</script>
<style lang="stylus">
.admin-banner-medias__top-bar
    margin-bottom 24px
    button
        margin-left 8px
// .stadium-boosts__new-list
//     background-color #fffbe6
//     padding 8px
//     margin-bottom 16px
</style>

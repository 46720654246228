<template>
    <VForm
        v-slot="{ form }"
        class="manager-create-form"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <UserCreateAndUpdateFormItems
            :form="form"
            :data="manager"
            :is-create-form="false"
            :enable-change-password="true" />
        <PeopleCreateAndUpdateFormItems
            :form="form"
            :data="manager"
            action-type="update" />
        <ManagerCreateAndUpdateFormItems
            :form="form"
            :data="manager" />
    </VForm>
</template>
<script>
import { rootActions, Getters } from '@/store/types'
import PeopleCreateAndUpdateFormItems from '@/components/PeopleCreateAndUpdateFormItems.vue'
import UserCreateAndUpdateFormItems from '@/components/UserCreateAndUpdateFormItems.vue'
import ManagerCreateAndUpdateFormItems from '@/components/ManagerCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'

export default {
    components: {
        PeopleCreateAndUpdateFormItems,
        UserCreateAndUpdateFormItems,
        ManagerCreateAndUpdateFormItems,
        VForm,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    computed: {
        manager () {
            return this.$store.getters[Getters.manager.GET_MANAGER](this.id)
        },
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.UPDATE_MANAGER, { id: this.id, ...values },
            )
            return res
        },
    },
}
</script>

<template>
    <div>
        <VFormItemDisableSwitch :form="form" :data="data" />
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ประเภท">
            <a-radio-group
                v-decorator="
                    [ 'amountType',
                      {
                          initialValue: DiscountType.PRICE
                      }
                    ]">
                <a-radio :value="DiscountType.PRICE">
                    {{ DiscountType.PRICE | formatDiscountTypeEnum }}
                </a-radio>
                <a-radio :value="DiscountType.PERCENT">
                    {{ DiscountType.PERCENT | formatDiscountTypeEnum }}
                </a-radio>
            </a-radio-group>
        </a-form-item>
        <VFormItemNumberWithBar
            v-show="form.getFieldValue('amountType') === DiscountType.PERCENT"
            name-prop="applicableValue"
            :form="form"
            :data="data"
            :min="0"
            :max="100"
            label="เงื่อนไข ใช้เงินคืน (%)"
            :required="true" />
        <a-form-item
            v-show="form.getFieldValue('amountType') === DiscountType.PRICE"
            label="สูงสุดไม่เกิน (บาท)"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-input-number
                v-decorator="
                    [ `applicableValue`,
                      { rules:
                          [
                              { required:
                                    true,
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },
                          ] } ]"
                :min="0" />
            = {{ form.getFieldValue(`applicableValue`) | formatCurrency }}
        </a-form-item>
        <a-form-item
            v-show="form.getFieldValue('amountType') === DiscountType.PERCENT"
            label="สูงสุดไม่เกิน (บาท)"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-input-number
                v-decorator="
                    [ `maximumApplicableAmount` ]"
                :min="0" />
            = {{ form.getFieldValue(`maximumApplicableAmount`) | formatCurrency }}
        </a-form-item>
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import { DiscountType } from '@/utils/enum'
import VFormItemNumberWithBar from '@/components/VFormItemNumberWithBar.vue'
import VFormItemDisableSwitch from '@/components/VFormItemDisableSwitch.vue'

export default {
    components: {
        VFormItemNumberWithBar,
        VFormItemDisableSwitch,
    },
    mixins: [FormItems],
    data () {
        return {
            DiscountType,
        }
    },
    methods: {
        mapDataToFormItems (data) {
            const {
                amountType,
                applicableValue,
                maximumApplicableAmount,
            } = data
            return {
                amountType,
                applicableValue,
                maximumApplicableAmount,
            }
        },
    },
}
</script>
<style lang="stylus">
</style>

<template>
    <div>
        <a-form-item
            v-if="!isBuyCondition"
            label="ราคาขั้นต่ำ"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-input-number
                v-decorator="
                    [ getFullNameProp('minPrice') ]"
                :min="1" />
            = {{ form.getFieldValue(getFullNameProp('minPrice')) | formatCurrency }}
        </a-form-item>
        <a-form-item
            label=" เฉพาะลูกค้าใหม่"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-switch
                v-decorator="
                    [ getFullNameProp('isOnlyNewUser'),
                      { valuePropName: 'checked',
                        initialValue: false },
                    ]" />
        </a-form-item>
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'

export default {
    mixins: [FormItems],
    props: {
        parentNameProp: {
            type: String,
            required: true,
        },
        isBuyCondition: {
            type: Boolean,
            required: false,
        },
    },
    methods: {
        mapDataToFormItems (data) {
            if (!data[this.parentNameProp]) return {}
            const {
                minPrice,
                isOnlyNewUser,
            } = data[this.parentNameProp]
            return {
                [this.getFullNameProp('minPrice')]: minPrice,
                [this.getFullNameProp('isOnlyNewUser')]: isOnlyNewUser,
            }
        },
        getFullNameProp (nameProp) {
            return `${this.parentNameProp }.${ nameProp}`
        },
    },

}
</script>

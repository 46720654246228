<template>
    <div>
        <VModal
            v-model="visibleUpdateForm"
            title="แก้ไข ข้อมูลผู้จัดการ">
            <ManagerUpdateForm :id="selectedEntityId" @success="closeUpdateForm" />
        </VModal>
        <VTable
            :data-source="dataSource"
            :columns="columns"
            :loading="loading"
            :pagination="pagination"
            :custom-row="createClickToEditRow"
            @change="handleTableChange">
            <template
                v-slot:operation="{ record }">
                <a-popconfirm
                    v-if="dataSource.length"
                    title="ยืนยันการลบ?"
                    @confirm="onConfirmDelete(record)">
                    <a href="javascript:;" @click.stop>ลบ</a>
                </a-popconfirm>
            </template>
        </VTable>
    </div>
</template>
<script>
import VTable from '@/components/VTable.vue'
import VModal from '@/components/VModal.vue'
import ManagerUpdateForm from '@/components/ManagerUpdateForm.vue'
import MixinTable from '@/mixins/MixinTable'

export default {
    components: {
        VTable,
        VModal,
        ManagerUpdateForm,
    },
    mixins: [MixinTable],
    props: {
        dataSource: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        pagination: {
            type: Object,
            default: undefined,
        },
    },
    data () {
        return {
            columns: [
                {
                    title: 'ชื่อจริง',
                    dataIndex: 'firstName',
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'นามสกุล',
                    dataIndex: 'lastName',
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'ชื่อเล่น',
                    dataIndex: 'nickName',
                    width: 100,
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'เบอร์โทร',
                    dataIndex: 'tel',
                    width: 100,
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'อีเมล',
                    dataIndex: 'email',
                    width: 100,
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'การดำเนินการ (Operation)',
                    dataIndex: 'operation',
                    width: 100,
                    fixed: 'right',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
        }
    },
}
</script>

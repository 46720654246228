<template>
    <VPage title="คูปองสำหรับแพ็กเกจ">
        <VModal
            v-model="visibleCreateForm"
            title="สร้างคูปองสำหรับแพ็กเกจ">
            <CouponForBundlePromotionCreateForm @success="onCreateEntityPagination" />
        </VModal>
        <div class="manager__top-bar">
            <a-input-search
                placeholder="ค้นหา"
                style="width: 400px;margin-right:18px;"
                @change="handleQueryChange" />
            <a-button
                type="primary"
                @click="openCreateForm">
                สร้าง
            </a-button>
        </div>
        <SectionNew v-if="newEntities.length > 0">
            <CouponForBundleTable
                :data-source="newEntities"
                :loading="loading"
                :custom-row="createClickToEditRow"
                :pagination="pagination"
                @change="handleTableChange"
                @delete="onDeleteEntityPagination" />
        </SectionNew>

        <CouponForBundleTable
            :data-source="dataSource"
            :loading="loading"
            :custom-row="createClickToEditRow"
            :pagination="pagination"
            @change="handleTableChange"
            @delete="onDeleteEntityPagination" />
    </VPage>
</template>
<script>
import EntitiesPagination from '@/mixins/EntitiesPagination'
import { rootActions } from '@/store/types'
import CouponForBundlePromotionCreateForm from '@/components/CouponForBundlePromotionCreateForm.vue'
import VModal from '@/components/VModal.vue'
import VPage from '@/components/VPage.vue'
import CouponForBundleTable from '@/components/CouponForBundleTable.vue'
import SectionNew from '@/components/SectionNew.vue'
import MixinDiscount from '@/mixins/MixinDiscount'

export default {
    components: {
        SectionNew,
        CouponForBundlePromotionCreateForm,
        CouponForBundleTable,
        VModal,
        VPage,
    },
    mixins: [EntitiesPagination, MixinDiscount],
    methods: {
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_COUPON_FOR_BUNDLES, { page, size },
            )
            return res
        },
        getAllEntities () {
            return this.$store.state.shop.couponForBundles
        },
        async delete (id) {
            await this.$store.dispatch(rootActions.DELETE_COUPON_FOR_BUNDLE, id)
        },
        async onOpenCreateForm () {
            this.openCreateForm()
        },
    },
}
</script>

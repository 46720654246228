<template>
    <VPage title="กิจกรรมชวนเพื่อน">
        <VModal
            v-model="visibleCreateForm"
            title="สร้างกิจกรรมชวนเพื่อน">
            <ReferralProgramCreateForm @success="onCreateEntityPagination" />
        </VModal>

        <div class="admin-referral-program__referral-program-section--active">
            กิจกรรมที่
            <a-tag color="#f50">
                ใช้งานอยู่
            </a-tag>
            <ReferralProgramTable
                :data-source="activeReferralProgram ? [ activeReferralProgram ] : []"
                :custom-row="createClickToEditRow"
                @delete="onDeleteEntityPagination" />
        </div>
        กิจกรรมทั้งหมด
        <div class="manager__top-bar">
            <a-input-search
                placeholder="ค้นหา"
                style="width: 400px;margin-right:18px;"
                @change="handleQueryChange" />
            <a-button
                type="primary"
                @click="onOpenCreateForm">
                สร้าง
            </a-button>
        </div>

        <SectionNew v-if="newEntities.length > 0">
            <ReferralProgramTable
                :data-source="newEntities"
                :loading="loading"
                :custom-row="createClickToEditRow"
                @delete="onDeleteEntityPagination" />
        </SectionNew>

        <ReferralProgramTable
            :data-source="dataSource"
            :loading="loading"
            :custom-row="createClickToEditRow"
            @delete="onDeleteEntityPagination" />
    </VPage>
</template>
<script>
import EntitiesPagination from '@/mixins/EntitiesPagination'
import { rootActions, Getters } from '@/store/types'
import ReferralProgramCreateForm from '@/components/ReferralProgramCreateForm.vue'
import VModal from '@/components/VModal.vue'
import VPage from '@/components/VPage.vue'
import ReferralProgramTable from '@/components/ReferralProgramTable.vue'
import SectionNew from '@/components/SectionNew.vue'

export default {
    components: {
        SectionNew,
        ReferralProgramCreateForm,
        ReferralProgramTable,
        VModal,
        VPage,
    },
    mixins: [EntitiesPagination],
    computed: {
        activeReferralProgram () {
            const id = this.$store.state.referral.activeReferralProgram
            return this.$store.getters[Getters.referral.GET_REFERRAL_PROGRAM](id)
        },
    },
    async created () {
        await this.$store.dispatch(rootActions.LOAD_ACTIVE_REFERRAL_PROGRAM)
        if (this.activeReferralProgram.referralPromotionId) {
            this.$store.dispatch(
                rootActions.LOAD_REFERRAL_PROMOTION,
                this.activeReferralProgram.referralPromotionId,
            )
        }
    },
    methods: {
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_REFERRAL_PROGRAMS, { page, size },
            )
            return res
        },
        getAllEntities () {
            return this.$store.state.referral.referralPrograms
        },
        async delete (id) {
            await this.$store.dispatch(rootActions.DELETE_REFERRAL_PROGRAM, id)
        },
        async onOpenCreateForm () {
            this.openCreateForm()
        },
    },
}
</script>
<style lang="stylus">
.admin-referral-program__referral-program-section--active
    margin-bottom 16px
</style>

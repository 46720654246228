<template>
    <div>
        <VFormItemDisableSwitch :form="form" :data="data" />
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ประเภทเงินคืน">
            <a-radio-group
                v-decorator="
                    [ 'amountType',
                      {
                          initialValue: CashbackType.EVERY_X_FOR_1
                      }
                    ]">
                <a-radio :value="CashbackType.EVERY_X_FOR_1">
                    {{ CashbackType.EVERY_X_FOR_1 | formatCashbackTypeEnum }}
                </a-radio>
                <a-radio :value="CashbackType.PERCENT">
                    {{ CashbackType.PERCENT | formatCashbackTypeEnum }}
                </a-radio>
            </a-radio-group>
        </a-form-item>
        <VFormItemNumberWithBar
            v-show="form.getFieldValue('amountType') === CashbackType.PERCENT"
            name-prop="cashbackValue"
            :form="form"
            :data="data"
            :min="0"
            :max="100"
            label="เงินคืน (%)"
            :required="true" />
        <a-form-item
            v-show="form.getFieldValue('amountType') === CashbackType.EVERY_X_FOR_1"
            label="ได้ 1 coin ทุกๆ"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-input-number
                v-decorator="
                    [ `cashbackValue`,
                      { rules:
                          [
                              { required:
                                    true,
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },
                          ] } ]"
                :min="0" />
            = {{ form.getFieldValue(`cashbackValue`) | formatCurrency }} ≈
            {{ form.getFieldValue(`cashbackValue`) ?
                Math.floor(1 / form.getFieldValue(`cashbackValue`) * 100) : "-" }}
            % cashback
        </a-form-item>
        <a-form-item
            v-show="form.getFieldValue('amountType') === CashbackType.PRICE"
            label="เงินคืน (บาท)"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-input-number
                v-decorator="
                    [ `cashbackValue`,
                      { rules:
                          [
                              { required:
                                    true,
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },
                          ] } ]"
                :min="0" />
            = {{ form.getFieldValue(`cashbackValue`) | formatCurrency }}
        </a-form-item>
        <a-form-item
            v-show="form.getFieldValue('amountType') !== CashbackType.PRICE"
            label="สูงสุดไม่เกิน (บาท)"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-input-number
                v-decorator="
                    [ `maximumCashbackAmount` ]"
                :min="0" />
            = {{ form.getFieldValue(`maximumCashbackAmount`) | formatCurrency }} / บิล
        </a-form-item>
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import { CashbackType } from '@/utils/enum'
import VFormItemNumberWithBar from '@/components/VFormItemNumberWithBar.vue'
import VFormItemDisableSwitch from '@/components/VFormItemDisableSwitch.vue'

export default {
    components: {
        VFormItemNumberWithBar,
        VFormItemDisableSwitch,
    },
    mixins: [FormItems],
    data () {
        return {
            CashbackType,
        }
    },
    methods: {
        mapDataToFormItems (data) {
            const {
                amountType,
                cashbackValue,
                maximumCashbackAmount,
            } = data
            return {
                amountType,
                cashbackValue,
                maximumCashbackAmount,
            }
        },
    },
}
</script>
<style lang="stylus">
</style>

<template>
    <div class="code">
        <a-descriptions
            v-if="referralPromotion"
            layout="horizontal"
            size="small"
            :column="6"
            bordered>
            <a-descriptions-item
                :span="3"
                label="ชื่อส่วนลด">
                {{ referralPromotion.name || "-" }}
            </a-descriptions-item>
            <a-descriptions-item
                :span="3"
                label="รายละเอียด">
                {{ referralPromotion.desc || "-" }}
            </a-descriptions-item>
            <a-descriptions-item
                v-if="referralPromotion.discountType === DiscountType.PERCENT"
                span="3"
                label="ส่วนลด (%)">
                {{ referralPromotion.discountValue || "-" }} %
            </a-descriptions-item>
            <a-descriptions-item
                v-if="referralPromotion.discountType === DiscountType.PRICE"
                span="3"
                label="ส่วนลด (บาท)">
                {{ referralPromotion.discountValue || "-" }} บาท
            </a-descriptions-item>
            <a-descriptions-item
                v-show="referralPromotion.discountType === DiscountType.PERCENT"
                span="3"
                label="ลดสูงสุด (บาท)">
                {{ referralPromotion.maxDiscountAmount || "-" | formatCurrency }}
            </a-descriptions-item>
            <a-descriptions-item
                :span="2"
                label="ราคาขั้นต่ำ">
                {{ referralPromotion.minPrice || "-" | formatCurrency }}
            </a-descriptions-item>
            <a-descriptions-item
                label=" เฉพาะลูกค้าใหม่">
                {{ referralPromotion.isOnlyNewUser ? "ใช่" : "ไม่" }}
            </a-descriptions-item>
            <a-descriptions-item
                label="อายุการใช้งาน">
                {{ referralPromotion.duration || "-" }} วัน
            </a-descriptions-item>
            <a-descriptions-item
                class="switch switch--disable"
                label="ระงับการใช้งาน">
                {{ referralPromotion.disabled ? "ใช่" : "ไม่" }}
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>
<script>
import { DiscountType } from '@/utils/enum'
import { Getters } from '@/store/types'

export default {
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            DiscountType,
        }
    },
    computed: {
        referralPromotion () {
            return this.$store.getters[Getters.shop.GET_COUPON_FOR_BUNDLE](this.id)
        },
    },
}
</script>
<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');

.code
    .ant-form-item
        margin-bottom 0
        label
            color $primary-color
.code__redeem-code .ant-form-item-control-wrapper
    font-family: 'Inconsolata', monospace;
    font-size: 16px
    font-weight: bold
.code__form-item--important  .ant-form-item-control-wrapper
    font-weight bold
    font-size 16px
.code__type-tag
    font-size: 16px;
    padding: 4px 16px 4px 16px;
    font-weight: 500;
</style>

<template>
    <VPage title="รายการสเตเดี้ยม">
        <VModal
            v-model="visibleUpdateForm"
            title="ข้อมูลสเตเดียม">
            <StadiumUpdateForm :stadium-id="selectedEntityId" @success="closeUpdateForm" />
        </VModal>
        <VModal
            v-model="visibleCreateForm"
            title="สร้างสเตเดียม">
            <StadiumCreateForm @success="onCreateEntityPagination" />
        </VModal>
        <div class="stadium-fields__top-bar">
            <a-button
                type="primary"
                class="stadium-holiday__add-button"
                @click="openCreateForm">
                สร้าง
            </a-button>
        </div>
        <div v-if="newEntities.length > 0" class="stadium-boosts__new-list">
            <TagNew />
            <VGrid
                v-slot="{ item }"
                :data-source="newEntities">
                <StadiumCard
                    :stadium="item"
                    @click.native="openUpdateForm(item.id)" />
            </VGrid>
        </div>
        <VGrid
            v-slot="{ item }"
            :loading="loading"
            :data-source="dataSource">
            <StadiumCard
                :stadium="item"
                @click.native="openUpdateForm(item.id)" />
        </VGrid>
        <a-pagination
            v-model="page"
            :page-size="size"
            :total="total" />
    </VPage>
</template>
<script>
import StadiumCard from '@/components/StadiumCard.vue'
import StadiumUpdateForm from '@/components/StadiumUpdateForm.vue'
import StadiumCreateForm from '@/components/StadiumCreateForm.vue'
import TagNew from '@/components/TagNew.vue'
import VGrid from '@/components/VGrid.vue'
import VModal from '@/components/VModal.vue'
import VPage from '@/components/VPage.vue'
import { rootActions } from '@/store/types'
import EntitiesPagination from '@/mixins/EntitiesPagination'

export default {
    components: {
        StadiumCard,
        StadiumUpdateForm,
        StadiumCreateForm,
        TagNew,
        VModal,
        VGrid,
        VPage,
    },
    mixins: [EntitiesPagination],
    methods: {
        getAllEntities () {
            return this.$store.state.stadium.stadiums
        },
        async fetchEntityPagination () {
            return this.$store.dispatch(
                rootActions.LOAD_STADIUMS,
                {
                    page: this.page,
                    size: this.size,
                },
            )
        },
    },
}
</script>
<style lang="stylus">
.stadium-fields__top-bar
    margin-bottom 24px
.stadium-holiday__add-button
    margin-bottom: 16px
.stadium-boosts__new-list
    background-color #fffbe6
    padding 8px
    margin-bottom 16px
</style>

<template>
    <VPage title="โค้ดส่วนลด">
        <div class="stadium-boosts">
            <a-tabs default-active-key="1">
                <a-tab-pane key="1">
                    <span slot="tab">
                        <a-icon type="double-right" />
                        กำลังจะมาถึง
                    </span>
                    <DiscountCodes :is-past="false" />
                </a-tab-pane>
                <a-tab-pane key="2">
                    <span slot="tab">
                        <a-icon type="history" />
                        ประวัติ
                    </span>
                    <DiscountCodes :is-past="true" />
                </a-tab-pane>
            </a-tabs>
        </div>
    </VPage>
</template>
<script>
import DiscountCodes from '@/components/DiscountCodes.vue'
import VPage from '@/components/VPage.vue'

export default {
    components: {
        DiscountCodes,
        VPage,
    },
}
</script>

<template>
    <div>
        <a-form-item
            label="สนามจดทะเบียนภาษีมูลค่าเพิ่ม (VAT)"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 10 }">
            <a-switch
                v-decorator="
                    [ `mainTaxInfo.hasVAT` ,
                      { valuePropName: 'checked',
                        initialValue: false },
                    ]"
                checked-children="จด Vat" />
        </a-form-item>
        <a-form-item
            label="คิดราคารวม VAT"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 10 }">
            <a-switch
                v-decorator="
                    [ `mainTaxInfo.isIncVAT` ,
                      { valuePropName: 'checked',
                        initialValue: false },
                    ]"
                checked-children="รวม Vat" />
        </a-form-item>
        <a-form-item
            label="เลขประจำตัวผู้เสียภาษี"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 10 }">
            <a-input
                v-decorator="
                    [ 'mainTaxInfo.taxNo' ]" />
        </a-form-item>
        <a-form-item
            label="ชื่อผู้เสียภาษี"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 10 }">
            <a-input
                v-decorator="
                    [ 'mainTaxInfo.name' ]" />
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ที่อยู่ผู้เสียภาษี">
            <a-textarea
                v-decorator="[ 'mainTaxInfo.addressString' ]"
                placeholder="บ้านเลขที่ ถนน อำเภอ จังหวัด"
                :auto-size="{ minRows: 2, maxRows: 6 }" />
        </a-form-item>

    </div>
</template>

<script>
import FormItems from '@/mixins/FormItems'

export default {
    mixins: [FormItems],
    methods: {
        mapDataToFormItems (data) {
            const { mainTaxInfo } = data
            if (!mainTaxInfo) return { mainTaxInfo: {} }
            const {
                taxNo,
                name,
                addressString,
                hasVAT,
                isIncVAT,
            } = mainTaxInfo
            return {
                mainTaxInfo: {
                    taxNo,
                    name,
                    addressString,
                    hasVAT,
                    isIncVAT,
                },
            }
        },
    },
}
</script>

<template>
    <VPage title="รางวัลคูปองส่วนลด">
        <VModal
            v-model="visibleCreateForm"
            title="สร้างรางวัล">
            <ReferralPromotionCreateForm @success="onCreateEntityPagination" />
        </VModal>
        <div class="manager__top-bar">
            <a-input-search
                placeholder="ค้นหา"
                style="width: 400px;margin-right:18px;"
                @change="handleQueryChange" />
            <a-button
                type="primary"
                @click="onOpenCreateForm">
                สร้าง
            </a-button>
        </div>
        <SectionNew v-if="newEntities.length > 0">
            <ReferralPromotionTable
                :data-source="newEntities"
                :loading="loading"
                :custom-row="createClickToEditRow"
                :pagination="pagination"
                @change="handleTableChange"
                @delete="onDeleteEntityPagination" />
        </SectionNew>

        <ReferralPromotionTable
            :data-source="dataSource"
            :loading="loading"
            :custom-row="createClickToEditRow"
            :pagination="pagination"
            @change="handleTableChange"
            @delete="onDeleteEntityPagination" />
    </VPage>
</template>
<script>
import EntitiesPagination from '@/mixins/EntitiesPagination'
import { rootActions } from '@/store/types'
import ReferralPromotionCreateForm from '@/components/ReferralPromotionCreateForm.vue'
import VModal from '@/components/VModal.vue'
import VPage from '@/components/VPage.vue'
import ReferralPromotionTable from '@/components/ReferralPromotionTable.vue'
import SectionNew from '@/components/SectionNew.vue'

export default {
    components: {
        SectionNew,
        ReferralPromotionCreateForm,
        ReferralPromotionTable,
        VModal,
        VPage,
    },
    mixins: [EntitiesPagination],
    methods: {
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_REFERRAL_PROMOTIONS, { page, size },
            )
            return res
        },
        getAllEntities () {
            return this.$store.state.referral.referralPromotions
        },
        async delete (id) {
            await this.$store.dispatch(rootActions.DELETE_REFERRAL_PROMOTION, id)
        },
        async onOpenCreateForm () {
            this.openCreateForm()
        },
    },
}
</script>

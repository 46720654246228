var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModal",
        {
          attrs: { title: "แก้ไขแพ๊กเกจคูปอง" },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("BundleCouponUpdateForm", {
            attrs: { id: _vm.selectedEntityId },
            on: { success: _vm.closeUpdateForm }
          })
        ],
        1
      ),
      _c("VTable", {
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          loading: _vm.loading,
          "custom-row": _vm.createClickToEditRow
        },
        scopedSlots: _vm._u([
          {
            key: "operation",
            fn: function(ref) {
              var record = ref.record
              return [
                _vm.dataSource.length
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "ยืนยันการลบ?" },
                        on: {
                          confirm: function($event) {
                            return _vm.onConfirmDelete(record)
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [_vm._v("ลบ")]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
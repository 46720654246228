<template>
    <div>
        <VModal
            v-model="visibleCreateForm"
            title="สร้างคูปองส่วนลด">
            <CouponCreateForm @success="onCreateEntityPagination" />
        </VModal>
        <div class="manager__top-bar">
            <a-input-search
                placeholder="ค้นหา"
                style="width: 400px;margin-right:18px;"
                @change="handleQueryChange" />
            <a-button
                type="primary"
                @click="openCreateForm">
                สร้าง
            </a-button>
        </div>
        <SectionNew v-if="newEntities.length > 0">
            <DiscountTable
                :promotion-code-type="PromotionCodeType.COUPON"
                :data-source="newEntities"
                :loading="loading"
                :custom-row="createClickToEditRow"
                @delete="onDeleteEntityPagination" />
        </SectionNew>
        <DiscountTable
            :promotion-code-type="PromotionCodeType.COUPON"
            :data-source="dataSource"
            :loading="loading"
            :custom-row="createClickToEditRow"
            :pagination="pagination"
            @change="handleTableChange"
            @delete="onDeleteEntityPagination" />
    </div>
</template>

<script>
import EntitiesPagination from '@/mixins/EntitiesPagination'
import MixinDiscount from '@/mixins/MixinDiscount'
import MixinDiscountPagination from '@/mixins/MixinDiscountPagination'
import { rootActions } from '@/store/types'
import CouponCreateForm from '@/components/CouponCreateForm.vue'
import VModal from '@/components/VModal.vue'
import DiscountTable from '@/components/DiscountTable.vue'
import SectionNew from '@/components/SectionNew.vue'

export default {
    components: {
        SectionNew,
        DiscountTable,
        CouponCreateForm,
        VModal,
    },
    mixins: [EntitiesPagination, MixinDiscount, MixinDiscountPagination],
    props: {
        isPast: {
            type: Boolean,
            default: null,
        },
    },
    methods: {
        async fetchEntityPagination () {
            const { page, size } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_DISCOUNT_COUPONS, { isPast: this.isPast, page, size },
            )
            return res
        },
        getAllEntities () {
            return this.$store.state.discount.discountCoupons
        },
        async delete (id) {
            await this.$store.dispatch(rootActions.DELETE_DISCOUNT_COUPON, id)
        },
        async onOpenCreateForm () {
            this.openCreateForm()
        },
    },
}
</script>

<template>
    <VForm
        v-slot="{ form }"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <SportCreateAndUpdateFormItems
            :form="form"
            :data="sport" />
    </VForm>
</template>
<script>
import VForm from '@/components/VForm.vue'
import { rootActions, Getters } from '@/store/types'
import Form from '@/mixins/Form'
import SportCreateAndUpdateFormItems from '@/components/SportCreateAndUpdateFormItems.vue'

export default {
    components: {
        VForm,
        SportCreateAndUpdateFormItems,
    },
    mixins: [Form],
    props: {
        sportId: {
            type: String,
            required: true,
        },
    },
    computed: {
        sport () {
            return this.$store.getters[Getters.stadium.GET_SPORT](this.sportId)
        },
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.UPDATE_SPORT, { id: this.sport.id, ...values },
            )
            return res
        },
    },
}
</script>

<template>
    <div>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ชื่อส่วนลด">
            <a-input
                v-decorator="[
                    'name',
                    { rules:
                        [
                            { required: true, message: 'กรุณากรอก ชื่อส่วนลด ด้วยค่ะ' },
                        ]
                    }
                ]"
                placeholder="ชื่อ เช่น ส่วนลดปีใหม่ 50% เฉพาะลูกค้าใหม่">
                <a-icon
                    slot="prefix"
                    type="user" />
            </a-input>
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="รายละเอียด">
            <a-textarea
                v-decorator="[ 'desc' ]"
                placeholder="รายละเอียดของส่วนลดแบบละเอียด"
                :auto-size="{ minRows: 2, maxRows: 6 }" />
        </a-form-item>
        <a-form-item
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            label="ประเภทส่วนลด">
            <a-radio-group
                v-decorator="
                    [ 'discountType',
                      {
                          initialValue: DiscountType.PRICE
                      }
                    ]">
                <a-radio :value="DiscountType.PRICE">
                    {{ DiscountType.PRICE | formatDiscountTypeEnum }}
                </a-radio>
                <a-radio :value="DiscountType.PERCENT">
                    {{ DiscountType.PERCENT | formatDiscountTypeEnum }}
                </a-radio>
            </a-radio-group>
        </a-form-item>
        <VFormItemNumberWithBar
            v-show="form.getFieldValue('discountType') === DiscountType.PERCENT"
            name-prop="discountValue"
            :form="form"
            :data="data"
            :min="1"
            :max="100"
            label="ส่วนลด (%)"
            :required="true" />
        <a-form-item
            v-show="form.getFieldValue('discountType') === DiscountType.PRICE"
            label="ส่วนลด (บาท)"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-input-number
                v-decorator="
                    [ `discountValue`,
                      { rules:
                          [
                              { required:
                                    true,
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },
                          ] } ]"
                :min="0" />
            = {{ form.getFieldValue(`discountValue`) | formatCurrency }}
        </a-form-item>
        <a-form-item
            v-show="form.getFieldValue('discountType') === DiscountType.PERCENT"
            label="ลดสูงสุด (บาท)"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-input-number
                v-decorator="
                    [ `maxDiscountAmount` ]"
                :min="1" />
            = {{ form.getFieldValue(`maxDiscountAmount`) | formatCurrency }}
        </a-form-item>
        <VFormItemItemCondition
            :data="data"
            :form="form"
            parent-name-prop="useCondition" />
        <VFormItemAmountWithUnlimitOption
            v-show="!referralMode && !couponForBundleMode"
            :form="form"
            :data="(referralMode || couponForBundleMode) ? { totalImportToStock: -1 } : data"
            label="จำนวนสิทธิ"
            name-prop="totalImportToStock" />
        <VFormItemAmountWithUnlimitOption
            v-if="!referralMode"
            v-show="!referralMode && !couponForBundleMode"
            :form="form"
            :data="couponForBundleMode ?
                { claimAmountLimitPerPerson: -1 } : data"
            label="จำนวนสิทธิ/1คน"
            name-prop="claimAmountLimitPerPerson"
            :initial-value="1" />
        <a-form-item
            v-if="referralMode || couponForBundleMode"
            label="อายุการใช้งาน"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-input-number
                v-decorator="
                    [ `duration`,
                      { rules:
                          [
                              { required:
                                    true,
                                message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },
                          ] } ]"
                :min="0" />
            = {{ form.getFieldValue(`duration`) }} วัน
        </a-form-item>
        <div v-else>
            <VFormItemRangeDateTime
                :data="data"
                :form="form"
                :required="true"
                @startDateTimeChange="onStartDateTimeChange" />
            <a-form-item
                label="เปิดให้เก็บได้"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }">
                <a-switch
                    :checked="isPublic"
                    @change="onToggleIsPublic" />
                <div>
                    <a-radio-group
                        v-show="isPublic"
                        v-model="publicTimeSettingMode"
                        @change="onChangePublicTimeSettingMode">
                        <a-radio :value="1">
                            พร้อมเวลาเริ่ม
                        </a-radio>
                        <a-radio :value="2">
                            อื่นๆ
                            <span v-show="isManualPublishedTimeSettingMode">
                                <VFormItemDateTime
                                    :form="form"
                                    :required="isManualPublishedTimeSettingMode"
                                    :name-prop="namePropManualPublishedOn"
                                    @change="onChangeManualPublishedOn" />
                            </span>
                        </a-radio>
                    </a-radio-group>
                    <div v-show="false">
                        <VFormItemDateTime
                            :form="form"
                            :data="data"
                            :name-prop="namePropPublishedOn" />
                    </div>
                </div>
            </a-form-item>
        </div>

        <a-form-item
            class="switch switch--disable"
            label="ระงับการใช้งาน"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }">
            <a-switch
                v-decorator="
                    [ `disabled` ,
                      { valuePropName: 'checked',
                        initialValue: false },
                    ]"
                checked-children="ระงับ" />
        </a-form-item>
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import VFormItemRangeDateTime from '@/components/VFormItemRangeDateTime.vue'
import VFormItemNumberWithBar from '@/components/VFormItemNumberWithBar.vue'
import VFormItemDateTime from '@/components/VFormItemDateTime.vue'
import VFormItemItemCondition from '@/components/VFormItemItemCondition.vue'
import { DiscountType } from '@/utils/enum'
import VFormItemAmountWithUnlimitOption from '@/components/VFormItemAmountWithUnlimitOption.vue'

export default {
    components: {
        VFormItemAmountWithUnlimitOption,
        VFormItemRangeDateTime,
        VFormItemNumberWithBar,
        VFormItemDateTime,
        VFormItemItemCondition,
    },
    mixins: [FormItems],
    props: {
        referralMode: {
            type: Boolean,
            default: false,
        },
        couponForBundleMode: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            DiscountType,
            isPublic: true,
            namePropPublishedOn: 'publishedOn',
            namePropManualPublishedOn: 'manualPublishedOn',
            namePropStart: 'start',
            publicTimeSettingMode: 1,
        }
    },
    computed: {
        isPublicEqualToStartSettingMode () {
            return this.isPublic && this.publicTimeSettingMode === 1
        },
        isManualPublishedTimeSettingMode () {
            return this.isPublic && this.publicTimeSettingMode === 2
        },
    },
    methods: {
        onStartDateTimeChange (value) {
            if (this.isPublicEqualToStartSettingMode) {
                this.setPublishedTime(value)
            }
        },
        onChangeManualPublishedOn (value) {
            this.setPublishedTime(value)
        },
        setPublishedTime (value) {
            this.form.setFieldsValue({
                [this.namePropPublishedOn]: value,
            })
        },
        setPublishedTimeEqualToStartTime () {
            this.form.setFieldsValue({
                [this.namePropPublishedOn]: this.form.getFieldValue(this.namePropStart),
            })
        },
        onToggleIsPublic (value) {
            this.isPublic = value
            this.form.setFieldsValue({
                forcedUnpublished: !this.isPublic,
            })
            this.recalPublishedTime()
        },
        onChangePublicTimeSettingMode () {
            this.recalPublishedTime()
        },
        recalPublishedTime () {
            if (this.isPublicEqualToStartSettingMode) return this.setPublishedTimeEqualToStartTime()
            return this.setPublishedTime(this.form.getFieldValue(this.namePropManualPublishedOn))
        },
        mapDataToFormItems (data) {
            const {
                name,
                desc,
                discountType,
                discountValue,
                maxDiscountAmount,
                publishedOn,
                forcedUnpublished,
                duration,
                validOn,
                disabled,
            } = data

            this.isPublic = !forcedUnpublished
            && !(publishedOn == null || publishedOn === undefined)
            this.publicTimeSettingMode = publishedOn.isSame(validOn) ? 1 : 2

            if (!this.referralMode && !this.couponForBundleMode) {
                this.form.getFieldDecorator('forcedUnpublished', { initialValue: !!forcedUnpublished, preserve: true })
            }

            return {
                name,
                desc,
                discountType,
                discountValue,
                maxDiscountAmount,
                duration,
                ...!this.referralMode && !this.couponForBundleMode
                    ? { [this.namePropManualPublishedOn]: data[this.namePropPublishedOn] } : {},
                disabled,
            }
        },
    },
}
</script>
<style lang="stylus">
.switch.switch--disable
    .ant-switch-checked
        background-color: #f5222d
</style>

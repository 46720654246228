<template>
    <div>
        <h3>{{ label }}</h3>
        <slot name="tooltip" />
        <br>
        <a-form-item
            v-for="(k,index) in form.getFieldValue('keys')"
            :key="k"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 12 }"
            :label="index + ''"
            v-bind="formItemLayout"
            :required="false">
            <a-card>
                <a-popconfirm
                    v-if="k >= minLength "
                    title="ยืนยันการลบ?"
                    @confirm="() => remove(index)">
                    <span
                        class="product-variant-form-items__remove-button"
                        @click.stop>
                        ลบ
                        <a-icon
                            v-if="k >= minLength "
                            class="dynamic-delete-button"
                            type="delete"
                            :style="{ fontSize: '16px' }" />
                    </span>
                </a-popconfirm>
                <br>
                <slot :item="getItem(index)" />
            </a-card>
            <a-button
                icon="up"
                :disabled="index === 0"
                @click="onClickUp(index)" />
            <a-button
                icon="down"
                :disabled="index + 1 >= form.getFieldValue('keys').length"
                @click="onClickDown(index)" />
        </a-form-item>
        <a-form-item v-bind="formItemLayoutWithOutLabel">
            <a-button
                type="dashed"
                style="width: 60%"
                @click="onClickAdd">
                <a-icon type="plus" /> {{ addButtonLabel }}
            </a-button>
        </a-form-item>
    </div>
</template>

<script>
import FormItems from '@/mixins/FormItems'

export default {
    components: {
    },
    mixins: [FormItems],
    props: {
        label: {
            type: String,
            required: true,
        },
        addButtonLabel: {
            type: String,
            default: 'เพิ่ม',
        },
        isUpdateForm: {
            type: Boolean,
            default: false,
        },
        propName: {
            type: String,
            required: true,
        },
        preventDefaultClickAdd: {
            type: Boolean,
            default: false,
        },
        // onAdd: {
        //     type: Function,
        //     required: true,
        // },
        minLength: {
            type: Number,
            default: 0,
        },
    },
    data () {
        return {
            nextDynamicFormId: 0,
            formItemLayout: {
                // labelCol: {
                //     xs: { span: 24 },
                //     sm: { span: 4 },
                // },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                },
            },
            formItemLayoutWithOutLabel: {
                wrapperCol: {
                    xs: { span: 24, offset: 0 },
                    sm: { span: 20, offset: 4 },
                },
            },
        }
    },
    mounted () {
        this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
        this.form.getFieldDecorator(this.propName, { initialValue: [], preserve: true })
        // if create form, add default variant
        if (!this.data || Object.keys(this.data).length === 0) {
            this.form.setFieldsValue({
                keys: [],
            })
            // use on mounted on parent
            // this.add()
            // this.form.getFieldDecorator('variants[0][name]', { initialValue: 'ธรรมดา' })
        }
    },
    methods: {
        onClickUp (index) {
            this.form.setFieldsValue({
                [this.propName]: this.swapArray(
                    this.form.getFieldValue(`${this.propName}`),
                    index, index - 1,
                ),
            })
        },
        onClickDown (index) {
            this.form.setFieldsValue({
                [this.propName]: this.swapArray(
                    this.form.getFieldValue(`${this.propName}`),
                    index,
                    index + 1,
                ),
            })
        },
        swapArray (array, index, withIndex) {
            const result = array;
            [result[withIndex], result[index]] = [array[index], array[withIndex]]
            return result
        },
        getItem (index) {
            return this.form.getFieldValue(`${this.propName}`)[index]
        },
        mapDataToFormItems (data) {
            this.reset()
            const values = data[this.propName]
            if (!values) return {}
            // 1. Add empty variants
            values.forEach((value) => this.add(value))
            // perform on component at slot body
            // 2. Fill value to empty variants
            // values.forEach((variant, index) => {
            //     Object.keys(variant).forEach((key) => {
            //         // ignore pricings because it will be handled by subcomponent
            //         if (key !== 'pricings') {
            //             this.form.getFieldDecorator(`variants[${index}][${key}]`)
            //             this.form.setFieldsValue({
            //                 [`variants[${index}][${key}]`]: variant[key],
            //             })
            //         }
            //     })
            // })
            return {}
        },
        remove (k) {
            this.removeItemOfProp(this.propName, k)
            this.removeItemOfProp('keys', k)
        },
        removeItemOfProp (propName, index) {
            const { form } = this
            // can use data-binding to get
            const keys = form.getFieldValue(propName)
            if (keys.length === 1) return
            keys.splice(index, 1)
            // can use data-binding to set
            form.setFieldsValue({
                [propName]: keys,
            })
        },
        onClickAdd () {
            this.$emit('clickAdd')
            if (!this.preventDefaultClickAdd) this.add()
        },
        add (value) {
            const { form } = this
            // can use data-binding to get
            const keys = form.getFieldValue('keys') ? form.getFieldValue('keys') : []
            const nextKeys = keys.concat(this.nextDynamicFormId)
            // can use data-binding to set
            // important! notify form to detect changes
            form.setFieldsValue({
                keys: nextKeys,
            })
            // perform on component at slot body
            // this.onAdd(value)
            const values = form.getFieldValue(this.propName)
                ? form.getFieldValue(this.propName)
                : []
            const nextValues = values.concat(value)
            form.setFieldsValue({
                [this.propName]: nextValues,
            })
            this.nextDynamicFormId += 1
        },
        reset () {
            this.form.setFieldsValue({
                keys: [],
            })
            this.form.setFieldsValue({
                [this.propName]: [],
            })
            this.nextDynamicFormId = 0
        },
    },
}
</script>
<style lang="stylus">
.product-variant-form-items__remove-button
    font-size: 12px
    line-height: 0
    padding: $padding = 6px
    position: absolute
    right: 0px
    top: 0
    z-index: 100
    width: auto
    display: inline-block
    cursor: pointer

    &:hover
        color: $primary-color
</style>

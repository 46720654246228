<template>
    <VForm
        v-slot="{ form }"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <CouponForBundleCreateAndUpdateFormItems :form="form" :data="couponForBundle" />
    </VForm>
</template>
<script>
import { rootActions, Getters } from '@/store/types'
import CouponForBundleCreateAndUpdateFormItems from '@/components/CouponForBundleCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'

export default {
    components: {
        CouponForBundleCreateAndUpdateFormItems,
        VForm,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    computed: {
        couponForBundle () {
            return this.$store.getters[Getters.shop.GET_COUPON_FOR_BUNDLE](this.id)
        },
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.UPDATE_COUPON_FOR_BUNDLE, { id: this.id, ...values },
            )
            return res
        },
    },
}
</script>

<template>
    <a-form-item
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 12 }"
        :label="label">
        <a-select
            v-decorator="[ propName ,
                           { rules:
                               [
                                   { required: true,
                                     message: 'เลือกสนามที่ต้องการโปรโมท', }
                               ] } ]"
            :dropdown-style="{ zIndex: 1000 }"
            show-search
            placeholder="เลือกรางวัล"
            option-filter-prop="children"
            style="width: 240px;"
            :filter-option="filterOption"
            :loading="loading"
            @focus="onFetchEntities">
            <a-select-option
                v-for="referralPromotion in entities"
                :key="referralPromotion.id"
                :value="referralPromotion.id">
                {{ referralPromotion.name }}
            </a-select-option>
        </a-select>
    </a-form-item>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import MixinReferralPromotionsPagination from '@/mixins/MixinReferralPromotionsPagination'

export default {
    mixins: [FormItems, MixinReferralPromotionsPagination],
    props: {
        label: {
            type: String,
            required: true,
        },
        propName: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            size: 200,
        }
    },
    methods: {
        mapDataToFormItems (data) {
            return {
                [this.propName]: data[this.propName],
            }
        },
        filterOption (input, option) {
            return (
                option.componentOptions.children[0]
                    .text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
    },
}
</script>
<style lang="stylus">
</style>

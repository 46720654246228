<template>
    <div>
        <a-form-item
            label="ชื่อที่ใช้แสดง">
            <a-input
                v-decorator="
                    [ 'displayName',
                      { rules:
                          [
                              { required: true,
                                message: 'กรุณาใส่ค่าในช่องนี้ด้วยค่ะ', } ] } ]" />
        </a-form-item>
        <VFormItemLogo
            :form="form"
            :data="data"
            label="ไอคอน (Icon)"
            prop-name="iconUrl" />
    </div>
</template>

<script>
import VFormItemLogo from '@/components/VFormItemLogo.vue'
import FormItems from '@/mixins/FormItems'

export default {
    components: {
        VFormItemLogo,
    },
    mixins: [FormItems],
    methods: {
        mapDataToFormItems (info) {
            return {
                displayName: info.displayName,
            }
        },
    },
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "กีฬา" } },
    [
      _c(
        "VModal",
        {
          attrs: { title: "Update Sport type" },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("SportUpdateForm", {
            attrs: { "sport-id": _vm.selectedEntityId },
            on: { success: _vm.closeUpdateForm }
          })
        ],
        1
      ),
      _c(
        "VModal",
        {
          attrs: { title: "Create Sport type" },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [_c("SportCreateForm", { on: { success: _vm.closeCreateForm } })],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { sm: { span: 24 }, xxl: { span: 14 } } },
            [
              _c(
                "div",
                { staticClass: "stadium-fields__top-bar" },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "stadium-holiday__add-button",
                      attrs: { type: "primary" },
                      on: { click: _vm.openCreateForm }
                    },
                    [_vm._v("\n                    Create\n                ")]
                  )
                ],
                1
              ),
              _c("VTable", {
                attrs: {
                  "enable-scroll": false,
                  columns: _vm.columns,
                  "data-source": _vm.dataSource,
                  loading: _vm.loading,
                  "custom-row": _vm.createClickToEditRow
                },
                scopedSlots: _vm._u([
                  {
                    key: "operation",
                    fn: function(ref) {
                      var record = ref.record
                      return [
                        _vm.dataSource.length
                          ? _c(
                              "a-popconfirm",
                              {
                                attrs: { title: "ยืนยันการลบ?" },
                                on: {
                                  confirm: function() {
                                    return _vm.onDelete(record.id)
                                  }
                                }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:;" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                      }
                                    }
                                  },
                                  [_vm._v("Delete")]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
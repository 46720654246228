<template>
    <div>
        <h3>สินค้าในแพ๊กเกจ</h3>
        <a-form-item
            v-for="(k) in form.getFieldValue('itemKeys')"
            :key="k"
            v-bind="formItemLayout"
            :required="false">
            <a-card>
                <a-popconfirm
                    v-if="k >= 1 "
                    title="ยืนยันการลบ?"
                    @confirm="() => remove(k)">
                    <span
                        class="product-variant-form-items__remove-button"
                        @click.stop>
                        ลบ
                        <a-icon
                            v-if="k >= 1 "
                            class="dynamic-delete-button"
                            type="delete"
                            :style="{ fontSize: '16px' }" />
                    </span>
                </a-popconfirm>
                <a-form-item
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 12 }"
                    label="สินค้า">
                    <a-select
                        v-decorator="[ `items[${k}][itemId]` ,
                                       { rules:
                                           [
                                               {
                                                   required: true,
                                                   message: 'เลือกสินค้าที่ต้องการใส่ในแพ็กเกจ',
                                               }
                                           ]
                                       }
                        ]"
                        show-search
                        placeholder="เลือกสินค้า"
                        option-filter-prop="children"
                        style="width: 240px;"
                        :filter-option="filterOption"
                        :loading="isLoadCouponForBundles"
                        @focus="loadCouponForBundles">
                        <a-select-option
                            v-for="couponForBundle in couponForBundles"
                            :key="couponForBundle.id"
                            :value="couponForBundle.id">
                            {{ couponForBundle.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item
                    label="จำนวนสินค้าต่อชุด"
                    help="เช่น ถ้าใส่ค่าเป็น 2 = ซื้อ 1 แพ๊กเกจ ก็คือจะได้ สินค้านี้ 2 ชิน"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 12 }">
                    <a-input-number
                        v-decorator="
                            [ `items[${k}][amount]`,
                              {
                                  rules:
                                      [
                                          { required: true,
                                            message: 'กรุณาใส่ค่าช่องนี้ด้วยค่ะ', },
                                      ] } ]"
                        :min="1" />
                    <template v-if="form.getFieldValue(`items[${k}][amount]`)">
                        = ซื้อ 1 ชุด
                        <a-icon type="arrow-right" />
                        ได้
                        <strong>
                            {{ form.getFieldValue(`items[${k}][amount]`) }}
                        </strong> ชิ้น
                    </template>
                </a-form-item>
                <ReferralPromotionById
                    v-if="form.getFieldValue(`items[${k}][itemId]`)"
                    :id="form.getFieldValue(`items[${k}][itemId]`)" />
            </a-card>
        </a-form-item>
        <a-form-item v-bind="formItemLayoutWithOutLabel">
            <a-button
                type="dashed"
                style="width: 60%"
                @click="add">
                <a-icon type="plus" /> เพิ่มสินค้าอื่น
            </a-button>
        </a-form-item>
    </div>
</template>

<script>
import FormItems from '@/mixins/FormItems'
import { rootActions } from '@/store/types'
import ReferralPromotionById from '@/components/ReferralPromotionById.vue'

export default {
    components: {
        ReferralPromotionById,
    },
    mixins: [FormItems],
    props: {
        type: {
            type: String,
            default: '',
        },
        suffixLabelPrice: {
            type: String,
            default: '',
        },
        isUpdateForm: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            isLoadCouponForBundles: false,
            nextDynamicFormId: 0,
            tooltipVisible: false,
            marks: {
                1: '1',
                10: '10',
                20: '20',
                30: '30',
                40: '40',
            },
            formItemLayout: {
                // labelCol: {
                //     xs: { span: 24 },
                //     sm: { span: 4 },
                // },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                },
            },
            formItemLayoutWithOutLabel: {
                wrapperCol: {
                    xs: { span: 24, offset: 0 },
                    sm: { span: 20, offset: 4 },
                },
            },
        }
    },
    computed: {
        couponForBundles () {
            return this.$store.state.shop.couponForBundles
        },
    },
    beforeMount () {
        this.loadCouponForBundles()
    },
    mounted () {
        this.form.getFieldDecorator('itemKeys', { initialValue: [], preserve: true })
        // if create form, add default variant
        if (!this.data || Object.keys(this.data).length === 0) {
            this.form.setFieldsValue({
                itemKeys: [],
            })
            this.add()
            // this.form.getFieldDecorator('item[0][name]', { initialValue: 'ธรรมดา' })
        }
    },
    methods: {
        async loadCouponForBundles () {
            this.isLoadCouponForBundles = true
            await this.$store.dispatch(
                rootActions.LOAD_COUPON_FOR_BUNDLES,
                { page: 1, size: 200 },
            )
            this.isLoadCouponForBundles = false
        },
        filterOption (input, option) {
            return (
                option.componentOptions.children[0]
                    .text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
        mapDataToFormItems (data) {
            this.reset()
            if (!data.items) return {}
            // 1. Add empty items
            data.items.forEach(() => this.add())
            // 2. Fill value to empty item
            data.items.forEach((item, index) => {
                Object.keys(item).forEach((key) => {
                    this.form.getFieldDecorator(`items[${index}][${key}]`)
                    this.form.setFieldsValue({
                        [`items[${index}][${key}]`]: item[key],
                    })
                })
            })
            return {}
        },
        remove (k) {
            const { form } = this
            // can use data-binding to get
            const itemKeys = form.getFieldValue('itemKeys')
            if (itemKeys.length === 1) return
            // can use data-binding to set
            form.setFieldsValue({
                itemKeys: itemKeys.filter((key) => key !== k),
            })
        },
        add () {
            const { form } = this
            // can use data-binding to get
            const itemKeys = form.getFieldValue('itemKeys') ? form.getFieldValue('itemKeys') : []
            const nextKeys = itemKeys.concat(this.nextDynamicFormId)
            // can use data-binding to set
            // important! notify form to detect changes
            form.setFieldsValue({
                itemKeys: nextKeys,
            })
            form.getFieldDecorator(
                `items[${this.nextDynamicFormId}][amount]`,
                { initialValue: 1 },
            )
            this.nextDynamicFormId += 1
        },
        reset () {
            this.form.setFieldsValue({
                itemKeys: [],
            })
            this.nextDynamicFormId = 0
        },
    },
}
</script>
<style lang="stylus">
.product-variant-form-items__remove-button
    font-size: 12px
    line-height: 0
    padding: $padding = 6px
    position: absolute
    right: 0px
    top: 0
    z-index: 100
    width: auto
    display: inline-block
    cursor: pointer
    &:hover
        color: $primary-color

</style>

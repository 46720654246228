var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "code" },
    [
      _c(
        "a-descriptions",
        { attrs: { layout: "vertical", size: "middle", bordered: "" } },
        [
          _c(
            "a-descriptions-item",
            { attrs: { span: 3, label: "ชื่อส่วนลด" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.referralPromotion.name || "-") +
                  "\n        "
              )
            ]
          ),
          _c(
            "a-descriptions-item",
            { attrs: { span: 3, label: "รายละเอียด" } },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.referralPromotion.desc || "-") +
                  "\n        "
              )
            ]
          ),
          _vm.referralPromotion.discountType === _vm.DiscountType.PERCENT
            ? _c("a-descriptions-item", { attrs: { label: "ส่วนลด (%)" } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.referralPromotion.discountValue || "-") +
                    " %\n        "
                )
              ])
            : _vm._e(),
          _vm.referralPromotion.discountType === _vm.DiscountType.PRICE
            ? _c("a-descriptions-item", { attrs: { label: "ส่วนลด (บาท)" } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.referralPromotion.discountValue || "-") +
                    " บาท\n        "
                )
              ])
            : _vm._e(),
          _c(
            "a-descriptions-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.referralPromotion.discountType ===
                    _vm.DiscountType.PERCENT,
                  expression:
                    "referralPromotion.discountType === DiscountType.PERCENT"
                }
              ],
              attrs: { label: "ลดสูงสุด (บาท)" }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("formatCurrency")(
                      _vm.referralPromotion.maxDiscountAmount || "-"
                    )
                  ) +
                  "\n        "
              )
            ]
          ),
          _c("a-descriptions-item", { attrs: { label: "ราคาขั้นต่ำ" } }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("formatCurrency")(
                    _vm.referralPromotion.minPrice || "-"
                  )
                ) +
                "\n        "
            )
          ]),
          _c("a-descriptions-item", { attrs: { label: " เฉพาะลูกค้าใหม่" } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.referralPromotion.isOnlyNewUser ? "ใช่" : "ไม่") +
                "\n        "
            )
          ]),
          _c("a-descriptions-item", { attrs: { label: "อายุการใช้งาน" } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.referralPromotion.duration || "-") +
                " วัน\n        "
            )
          ]),
          _c(
            "a-descriptions-item",
            {
              staticClass: "switch switch--disable",
              attrs: { label: "ระงับการใช้งาน" }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.referralPromotion.disabled ? "ใช่" : "ไม่") +
                  "\n        "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
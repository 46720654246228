var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VCard", {
    attrs: {
      "placeholder-label": "ไม่มีรูป Banner",
      title: _vm._f("getStartEndPrettyString")(_vm.bannerMedia),
      "cover-photo-url": _vm.imageUrl,
      "cover-photo-ratio": _vm.ImageSize.w / _vm.ImageSize.h,
      "enable-delete": _vm.enableDelete
    },
    on: { delete: _vm.deleteBannerMedia },
    scopedSlots: _vm._u(
      [
        _vm._l(_vm.$slots, function(index, name) {
          return {
            key: name,
            fn: function() {
              return [_vm._t(name)]
            },
            proxy: true
          }
        })
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
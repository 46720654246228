<template>
    <div>
        <VModalVue
            v-model="visibleSelectBannerMedia"
            title="เลือกรายการ banner">
            <AdminBannerMediasVue
                :disable-open-update-form-on-click="true"
                @clickItem="onSelectBannerMedia" />
        </VModalVue>
        <VModalVue
            v-model="visibleUpdateForm"
            :after-close="clearSelectedEntityId"
            title="แก้ไขรายการ Banner">
            <BannerMediaUpdateFormVue
                :id="selectedEntityId"
                @success="closeUpdateForm" />
        </VModalVue>

        <VFormItemArrayVue
            ref="bannerMediaFormItem"
            :form="form"
            :data="data"
            :is-update-form="isUpdateForm"
            label="รายการ Banner"
            prop-name="bannerMediaIds"
            :prevent-default-click-add="true"
            @clickAdd="onClickAdd">
            <template
                v-slot="{ item }">
                <div
                    v-if="getBannerMediaById(item)"
                    :class="{ 'has-error': getBannerMediaById(item).end.isBefore(moment()) }">
                    {{ getBannerMediaById(item).end.isBefore(moment())
                        ? "หมดอายุแล้ว (จะไม่แสดงผลในแอป)" : "" }}
                    <div class="banner-media__container">
                        <BannerMediaCardVue
                            :banner-media="getBannerMediaById(item)"
                            :enable-delete="false"
                            @click.native=" openUpdateForm(item)" />
                    </div>
                </div>
            </template>
        </VFormItemArrayVue>
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import VFormItemArrayVue from '@/components/VFormItemArray.vue'
import VModalVue from '@/components/VModal.vue'
import AdminBannerMediasVue from '@/views/AdminBannerMedias.vue'
import BannerMediaCardVue from '@/components/BannerMediaCard.vue'
import { Getters } from '@/store/types'
import moment from 'moment'
import MixinUpdateFormModal from '@/mixins/MixinUpdateFormModal'
import BannerMediaUpdateFormVue from '@/components/BannerMediaUpdateForm.vue'
import { EntityImageSize } from '@/utils/enum'

export default {
    components: {
        VFormItemArrayVue,
        VModalVue,
        AdminBannerMediasVue,
        BannerMediaCardVue,
        BannerMediaUpdateFormVue,
    },
    mixins: [FormItems, MixinUpdateFormModal],
    props: {
        isUpdateForm: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            moment,
            visibleSelectBannerMedia: false,
            ImageSize: EntityImageSize.BANNER,
        }
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        mapDataToFormItems (data) {
        },
        onClickAdd () {
            this.visibleSelectBannerMedia = true
        },
        // onAdd (value) {
        // const { form } = this
        // const keys = form.getFieldValue('bannerMediaIds')
        //     ? form.getFieldValue('bannerMediaIds')
        //     : []
        // console.log(keys)
        // console.log(value)
        // const nextKeys = keys.concat(value)
        // // can use data-binding to set
        // // important! notify form to detect changes
        // console.log(nextKeys)
        // form.setFieldsValue({
        //     bannerMediaIds: nextKeys,
        // })
        // console.log(this.$refs.bannerMediaFormItem.nextDynamicFormId)
        // console.log(`bannerMediaIds[${this.$refs.bannerMediaFormItem.nextDynamicFormId}]`)
        // this.form.getFieldDecorator(
        //     `bannerMediaIds[${this.$refs.bannerMediaFormItem.nextDynamicFormId}]`,
        // )
        // this.form.setFieldsValue({
        //     [`bannerMediaIds[${this.$refs.bannerMediaFormItem.nextDynamicFormId}]`]: value,
        // })
        // console.log(this.form.getFieldValue(
        //     `bannerMediaIds[${this.$refs.bannerMediaFormItem.nextDynamicFormId}]`,
        // ))
        // },
        onSelectBannerMedia (item) {
            this.visibleSelectBannerMedia = false
            this.$refs.bannerMediaFormItem.add(item.id)
        },
        getBannerMediaById (id) {
            return this.$store.getters[Getters.banner.GET_BANNER_MEDIA](id)
        },
    },
}
</script>
<style lang="stylus">
.has-error
    color:#f5222d
    .banner-media__container
        border: 1px solid #f5222d;
</style>

<template>
    <VPage title="กิจกรรม รับเงินคืน(Lenkila Coin)">
        <!-- <VModal
            v-model="visibleCreateForm"
            title="สร้างกิจกรรมรับเงินคืน">
            <CashbackProgramCreateForm @success="onCreateEntityPagination" />
        </VModal> -->

        <div class="admin-cashback-program__cashback-program-section--active">
            <!-- กิจกรรมที่ -->
            <!-- <a-tag color="#f50">
                ใช้งานอยู่
            </a-tag> -->
            <CashbackProgramUpdateForm
                v-if="activeCashbackProgramId != null
                    || activeCashbackProgramId != undefined "
                :id="activeCashbackProgramId" />
        </div>
        <!-- กิจกรรมทั้งหมด
        <div class="manager__top-bar">
            <a-input-search
                placeholder="ค้นหา"
                style="width: 400px;margin-right:18px;"
                @change="handleQueryChange" />
            <a-button
                type="primary"
                @click="onOpenCreateForm">
                สร้าง
            </a-button>
        </div>

        <SectionNew v-if="newEntities.length > 0">
            <CashbackProgramTable
                :data-source="newEntities"
                :loading="loading"
                :custom-row="createClickToEditRow"
                @delete="onDeleteEntityPagination" />
        </SectionNew>

        <CashbackProgramTable
            :data-source="dataSource"
            :loading="loading"
            :custom-row="createClickToEditRow"
            @delete="onDeleteEntityPagination" /> -->
    </VPage>
</template>
<script>
import { rootActions } from '@/store/types'
import CashbackProgramUpdateForm from '@/components/CashbackProgramUpdateForm.vue'
import VPage from '@/components/VPage.vue'

export default {
    components: {
        CashbackProgramUpdateForm,
        VPage,
    },
    // mixins: [EntitiesPagination],
    computed: {
        // activeCashbackProgram () {
        //     const id = this.$store.state.cashback.activeCashbackProgram
        //     return this.$store.getters[Getters.cashback.GET_CASHBACK_PROGRAM](id)
        // },
        activeCashbackProgramId () {
            return this.$store.state.cashback.activeCashbackProgram
        },
    },
    async created () {
        await this.$store.dispatch(rootActions.LOAD_CASHBACK_PROGRAM)
    },
    // methods: {
    // async fetchEntityPagination () {
    //     const { page, size } = this
    //     const res = await this.$store.dispatch(
    //         rootActions.LOAD_REFERRAL_PROGRAMS, { page, size },
    //     )
    //     return res
    // },
    // getAllEntities () {
    //     return this.$store.state.cashback.cashbackPrograms
    // },
    // async delete (id) {
    //     await this.$store.dispatch(rootActions.DELETE_REFERRAL_PROGRAM, id)
    // },
    // async onOpenCreateForm () {
    //     this.openCreateForm()
    // },
    // },
}
</script>
<style lang="stylus">
.admin-cashback-program__cashback-program-section--active
    margin-bottom 16px
</style>

<template>
    <VCard
        placeholder-label="ไม่มีรูป Banner"
        :title="bannerMedia | getStartEndPrettyString"
        :cover-photo-url="imageUrl"
        :cover-photo-ratio="ImageSize.w / ImageSize.h"
        :enable-delete="enableDelete"
        @delete="deleteBannerMedia">
        <template v-for="(index, name) in $slots" v-slot:[name]>
            <slot :name="name" />
        </template>
    </VCard>
</template>
<script>
import { rootActions } from '@/store/types'
import VCard from '@/components/VCard.vue'
import { EntityImageSize } from '@/utils/enum'

export default {
    components: {
        VCard,
    },
    props: {
        bannerMedia: {
            type: Object,
            required: true,
        },
        onDelete: {
            type: Function,
            // We can't use default function because we lose the this binding.
            default: null,
        },
        enableDelete: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            ImageSize: EntityImageSize.BANNER,
        }
    },
    computed: {
        imageUrl () {
            return this.bannerMedia.imageUrl
        },
    },
    methods: {
        deleteBannerMedia () {
            this.$store.dispatch(
                rootActions.DELETE_BANNER_MEDIA,
                this.bannerMedia.id,
            )
        },
    },
}
</script>

<style lang="stylus">
.bannerMedia-card__type
    position: relative
    color: white
    padding: 8px

.bannerMedia-card__tag
    position: absolute
    color: white

.bannerMedia-card__buffet-type
    background: $blue-color
    color: white

.bannerMedia-card__reservation-type
    background: $green-color
    color: white

.bannerMedia-card__full-price
    font-size: 12px

.bannerMedia-card__price
    font-size: 14px
    font-weight: 500

.bannerMedia-card__type-addional-info
    padding: 0 0 0 8px

.bannerMedia-card__tag__full
    background: $primary-color
</style>

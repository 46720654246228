<template>
    <div>
        <a-form-item
            label="ชื่อกิจกรรม">
            <a-input
                v-decorator="
                    [ 'title',
                      { rules:
                          [
                              { required: true,
                                message: 'กรุณาใส่ชื่อด้วยค่ะ', } ] } ]" />
        </a-form-item>
        <a-form-item
            label="คำบรรยายเบื้องต้น">
            <a-textarea
                v-decorator="[ 'desc' ]"
                :auto-size="{ minRows: 2, maxRows: 6 }" />
        </a-form-item>
        <ReferralPromotionPickerFormItem
            :form="form"
            :data="data"
            :prop-name="referralPropName"
            label="รางวัลสำหรับคนถูกชวน" />
        <a-form-item
            v-if="selectedReferralPromotion()"
            label="รายละเอียด">
            <ReferralPromotion
                :referral-promotion="selectedReferralPromotion()" />
        </a-form-item>
        <ReferralPromotionPickerFormItem
            :form="form"
            :data="data"
            :prop-name="referrerPropName"
            label="รางวัลสำหรับคนชวน" />
        <a-form-item
            v-if="selectedReferrerPromotion()"
            label="รายละเอียด">
            <ReferralPromotion
                :referral-promotion="selectedReferrerPromotion()" />
        </a-form-item>
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import ReferralPromotionPickerFormItem from '@/components/ReferralPromotionPickerFormItem.vue'
import ReferralPromotion from '@/components/ReferralPromotion.vue'
import { Getters } from '@/store/types'

export default {
    components: {
        ReferralPromotionPickerFormItem,
        ReferralPromotion,
    },
    mixins: [FormItems],
    data () {
        return {
            referralPropName: 'referralPromotionId',
            referrerPropName: 'referrerPromotionId',
        }
    },
    methods: {
        selectedReferralPromotion () {
            const id = this.form.getFieldValue('referralPromotionId')
            if (id) return this.$store.getters[Getters.referral.GET_REFERRAL_PROMOTION](id)
            return null
        },
        selectedReferrerPromotion () {
            const id = this.form.getFieldValue('referrerPromotionId')
            if (id) return this.$store.getters[Getters.referral.GET_REFERRAL_PROMOTION](id)
            return null
        },
        mapDataToFormItems (data) {
            const {
                title,
                desc,
            } = data
            return {
                title,
                desc,
            }
        },
    },
}
</script>
<style lang="stylus">
</style>

<template>
    <VForm
        v-slot="{ form }"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <ReferralProgramCreateAndUpdateFormItems :form="form" />
    </VForm>
</template>
<script>
import { rootActions } from '@/store/types'
import VForm from '@/components/VForm.vue'
import ReferralProgramCreateAndUpdateFormItems from '@/components/ReferralProgramCreateAndUpdateFormItems.vue'

export default {
    components: {
        VForm,
        ReferralProgramCreateAndUpdateFormItems,
    },
    methods: {
        async submit (values) {
            const res = await this.$store.dispatch(
                rootActions.ADD_REFERRAL_PROGRAM, { ...values },
            )
            return res
        },
    },
}
</script>

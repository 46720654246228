var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        {
          attrs: {
            label: "เข้าร่วมในระบบแล้ว",
            "label-col": { span: 6 },
            "wrapper-col": { span: 10 }
          }
        },
        [
          _c("a-switch", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "isOfficial",
                  { valuePropName: "checked", initialValue: false }
                ],
                expression:
                  "\n                [ `isOfficial` ,\n                  { valuePropName: 'checked',\n                    initialValue: false },\n                ]"
              }
            ],
            attrs: { "checked-children": "Lenkila Official" }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            label: "\n            คอมมิชชั่น\n            (%)",
            "label-col": { span: 6 },
            "wrapper-col": { span: 10 }
          }
        },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["generalCommissionRate"],
                expression: "\n                [ 'generalCommissionRate' ]"
              }
            ],
            attrs: { min: 0 }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        {
          attrs: {
            label: "คอมมิชชั่น Ads (%)",
            "label-col": { span: 6 },
            "wrapper-col": { span: 10 }
          }
        },
        [
          _c("a-input-number", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["adsCommissionRate"],
                expression: "\n                [ 'adsCommissionRate' ]"
              }
            ],
            attrs: { min: 0 }
          })
        ],
        1
      ),
      _c("StadiumTaxCreateAndUpdateFormItems", {
        attrs: { form: _vm.form, data: _vm.data }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <DiscountTable
            :expire-by-duration="true"
            :promotion-code-type="PromotionCodeType.COUPON"
            :data-source="dataSource"
            :loading="loading"
            :custom-row="createClickToEditRow"
            :pagination="pagination"
            @change="handleTableChange"
            @delete="(value)=>this.$emit('delete',value)">
            <template
                v-slot:updateForm="{ selectedEntityId,onSuccess }">
                <CouponForBundleUpdateForm
                    :id="selectedEntityId"
                    @success="onSuccess" />
            </template>
        </DiscountTable>
    </div>
</template>
<script>
import MixinTable from '@/mixins/MixinTable'
import DiscountTable from '@/components/DiscountTable.vue'
import CouponForBundleUpdateForm from '@/components/CouponForBundleUpdateForm.vue'
import { PromotionCodeType } from '@/utils/enum'

export default {
    components: {
        DiscountTable,
        CouponForBundleUpdateForm,
    },
    mixins: [MixinTable],
    data () {
        return {
            PromotionCodeType,
        }
    },
}
</script>
